import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import burgeramahome from "../../images/CaseStudy/burgerama/burgeramahome.png";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import burgeramaowner from "../../images/CaseStudy/burgerama/burgeramafounder.jpg"
import burgeramaone from '../../images/CaseStudy/burgerama/Burgerama 1.png';
import burgeramatwo from '../../images/CaseStudy/burgerama/Burgerama 2.png';
import burgeramathree from '../../images/CaseStudy/burgerama/burgeramathree.png';
import burgeramafour from '../../images/CaseStudy/burgerama/burgeramafour.png';
import burgeramafive from '../../images/CaseStudy/burgerama/burgeramafive.png';



const Burgerama = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
    }, []);


    const infoAboutCompany = {
        Brand: `Burgerama`,
        Platform: "Zomato & Swiggy",
        Cuisine: "Cafe",
        Location: "Mumbai",
        AOV: "High",
        "Target Audience": "Premium",
    };

    const challenge = {
        text1: "Fragmented feedback, tracking issues, weak retention",
        text2: `As a rapidly expanding restaurant chain, BURGERAMA grappled with several hurdles in managing customer feedback across platforms like Zomato, Swiggy, and Google. The process was fragmented, making it difficult to derive meaningful insights. Key challenges included the inability to centralize reviews, identify trends in operational issues like delays or quality, and track store-wise or menu-specific performance. Additionally, tracking response times and managing customer queries across platforms created inefficiencies, while a lack of differentiation between new and repeat customers hindered tailored retention strategies.`,
    };

    const impact = {
        impactCards: {
            card1: {
                header: "Engagement",
                figures: 0,
                figurePrefix: "",
                figuresPostfix: "%",
                content: "Ratings on aggregator platforms saw a significant boost, attracting new customers and increasing visibility.",
            },
            card2: {
                header: "Conversion",
                figures: 0,
                figuresPostfix: "%",
                content: "Increase repeat visits and encouraging word-of-mouth recommendations.",
            },
            card3: {
                header: "Engagement",
                figures: 0,
                figuresPostfix: "%",
                content: "Improving customer satisfaction and overall experience.",
            },
        },
        owner_views: {
            title: "Burgerama",
            description: `For our team, after onboarding restaverse, the store-wise performance tracking has been a game changer for them. It’s much easier now to see how each outlet is doing and take steps to address specific issues. The reports have saved us a ton of time by summarising everything in one place. Tracking queries and responses is much simpler now. AI-generated responses add a nice personalised touch, even if we need to tweak them sometimes.`,
            name: "Viraaj Badhwar",
            designation: "Co-Founder, Burgerama",
            owner_img: burgeramaowner
        },
    };


    const solutions = {
        1: {
            header: "Centralized Dashboard",
            content: `Platform visibility was enhanced using advanced SEO techniques to improve rankings on aggregator platforms. This optimization ensured the outlet appeared prominently in customer searches and recommendations, resulting in increased exposure and higher organic traffic.`,
            src: burgeramaone,
        },
        2: {
            header: "Comprehensive Reports",
            content:
                "The team was trained on critical operational practices, including maintaining consistent store availability to maximize potential orders, managing inventory accurately to minimize order rejections, and leveraging performance metrics to identify and address key areas for improvement.",
            src: burgeramatwo,
        },
        3: {
            header: "Advanced Analytics",
            content:
                "Features such as mealtime-specific poor rating splits, AOV-based analysis, and new vs. repeat customer ratings enabled precise trend analysis.",
            src: burgeramathree,
        },
        4: {
            header: "AI-Powered Personalization",
            content:
                "Automated responses to reviews with a personalized touch saved time while maintaining quality.",
            src: burgeramafour,
        },
        5: {
            header: "AI-Powered Personalization",
            content:
                "Store-level performance metrics and response time tracking (e.g., achieving 80% responses within six hours) ensured accountability.",
            src: burgeramafive,
        }
    };

    return (
        <>
            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div
                    // className="casestudy "

                    className="container-lg"
                >
                    <div
                        className="casestudy"
                        style={{ fontFamily: "Poppins , sans-serif" }}
                    >
                        <div className="main-section">
                            {/* left-main-section */}
                            <div className="left-main-section">
                                <div className="left-section-desc">
                                    Burgerama: Centralised reviews and complaints management system
                                </div>
                                <div className="info-tags">
                                    {Object.keys(infoAboutCompany).map((key) => (
                                        <Infotags
                                            key={key}
                                            header={key}
                                            value={infoAboutCompany[key]}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* image */}
                            <img src={burgeramahome} className="image-main-section"></img>
                        </div>


                        <div className="sections">
                            <nav className="section-navbar">
                                <a href="#challenge">Challenge</a>
                                <a href="#solution">Solution</a>
                                <a href="#impact">Impact</a>
                            </nav>

                            <section id="challenge">
                                <ChallengeSection
                                    text1={challenge.text1}
                                    text2={challenge.text2}
                                />
                                {/* <Challenge/> */}
                            </section>

                            <section id="solution">
                                <SolutionSection solutions={solutions} />
                            </section>

                            <section id="impact">
                                {/* <Impact /> */}
                                <ImpactSection impact={impact} />
                            </section>

                            <div className="cardcase" ref={ref}>
                                {inView && (
                                    <div className="cardcasestudyHeader animate-from-top">
                                        Success Story
                                    </div>
                                )}

                                <CaseStudyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <SuceessStory
                    titletext="Be ahead of the curve"
                    desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
                    buttontext="Join Waitlist"
                    redirect="/contact-us"
                />
            </div>
        </>
    );
};

export default Burgerama;
