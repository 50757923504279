import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import meethaone from "../../images/CaseStudy/meetha/Meetha 1.webp";
import meethatwo from "../../images/CaseStudy/meetha/Meetha 2.webp";
import meethaowner from '../../images/CaseStudy/meetha/Meetha.webp';
import meethahome from "../../images/CaseStudy/meetha/Meetha Home.webp";

const Meetha = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  

  useEffect(() => {
    document.title = "Success Stories | Restaverse";
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
 

  const infoAboutCompany = {
    Brand: `Meetha`,
    Platform: "Zomato & Swiggy",
    Cuisine: "Mithai/Desserts",
    Location: "Mumbai",
    AOV: "High",
    "Target Audience": "Premium",
  };

  const challenge = {
    text1: "Boosting Visibility and Efficiency",
    text2: `Meetha, a well-established sweet brand renowned for its excellence in dining services, faced a critical challenge in its online delivery operations. Despite its strong offline presence and brand recognition, the revenue generated from online delivery services was below expectations. This discrepancy indicated a significant gap between Meetha's offline success and its ability to leverage the online market effectively, impacting overall revenue potential in the competitive confectionery industry.
`,
  };

  const impact = {
    impactCards: {
      card1: {
        header: "Revenue",
        figures: 17,
        figurePrefix: "",
        figuresPostfix: "%",
        content: "Growth in overall revenue",
      },
      card2: {
        header: "Conversion",
        figures: 27,
        figuresPostfix: "%",
        content: "increase in overall menu opens",
      },
      card3: {
        header: "Orders Conversion",
        figures: 34,
        figuresPostfix: "%",
        content: "increase in overall Orders",
      },
    },
    owner_views: {
      title: "Meetha",
      description: `Restaverse has been pivotal in transforming Meetha into a thriving brand. Their tailored strategies and keen insights boosted our online visibility and order volume and they also helped us to improve our overall operational efficiency. With their support, we've successfully bridged the gap between our strong offline presence and online potential, leading to substantial growth in revenue and customer satisfaction. Restaverse's partnership has been instrumental in our expansion, culminating in the exciting milestone of opening a new outlet recently.
`,
      name: "Team Meetha",
      designation: "Radisson Hotel Group",
      owner_img: meethaowner
    },
  };


    const solutions = {
      1: {
        header: " Exclusive Online Menu",
        content: `Upon partnering with Meetha, we recognized their challenge of underperforming online delivery revenue despite their strong offline presence as a renowned sweet brand. Our approach began with a thorough analysis of their customer base and market dynamics. This allowed us to refine Meetha's menu offerings strategically, optimising descriptions to increase customer engagement and conversion rates immediately after menu updates.`, 
        src: meethaone,
      },
      2: {
        header: "SEO Enhancement",
        content:
          "We enhanced Meetha's online visibility and order volume through effective SEO strategies and improved placement on key food delivery platforms. This boosted their search rankings and attracted more customers to view their menu, leading to significant growth in online sales.",
        src: meethatwo,
      },
      
 
    };

  return (
    <>
      <div style={{ backgroundColor: "#f7f5ff" }}>
        <div
          // className="casestudy "

          className="container-lg"
        >
          <div
            className="casestudy"
            style={{ fontFamily: "Poppins , sans-serif" }}
          >
            <div className="main-section">
              {/* left-main-section */}
              <div className="left-main-section">
                <div className="left-section-desc">
                Meetha: Revenue growth via through effective SEO strategies
                </div>
                <div className="info-tags">
                  {Object.keys(infoAboutCompany).map((key) => (
                    <Infotags
                      key={key}
                      header={key}
                      value={infoAboutCompany[key]}
                    />
                  ))}
                </div>
              </div>

              {/* image */}
              <img src={meethahome} className="image-main-section"></img>
            </div>

            {/* Challenge Solution Impact */}
            <div className="sections">
              <nav className="section-navbar">
                <a href="#challenge">Challenge</a>
                <a href="#solution">Solution</a>
                <a href="#impact">Impact</a>
              </nav>

              <section id="challenge">
                <ChallengeSection
                  text1={challenge.text1}
                  text2={challenge.text2}
                />
                {/* <Challenge/> */}
              </section>

              <section id="solution">
                <SolutionSection solutions={solutions}/>
              </section>

              <section id="impact">
                {/* <Impact /> */}
                <ImpactSection impact={impact} />
              </section>

              <div className="cardcase" ref={ref}>
                {inView && (
                  <div className="cardcasestudyHeader animate-from-top">
                    Success Story
                  </div>
                )}

              <CaseStudyCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <SuceessStory
          titletext="Be ahead of the curve"
          desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
          buttontext="Join Waitlist"
          redirect="/contact-us"
        />
      </div>
    </>
  );
};

export default Meetha;
