import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import burmahome from "../../images/CaseStudy/burma/burmahome.png";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import burmaowner from "../../images/CaseStudy/burma/burmaburmafounder.png";
import burmaone from '../../images/CaseStudy/burma/Burma Burma 1.png';
import burmatwo from '../../images/CaseStudy/burma/BUrm Burma 2.png';


const Burma = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
    }, []);


    const infoAboutCompany = {
        Brand: `Burma Burma`,
        Platform: "Zomato & Swiggy",
        Cuisine: "Burmese",
        Location: "Mumbai, Bengaluru, Delhi, Hyderabad",
        AOV: "High",
        "Target Audience": "Premium",
    };

    const challenge = {
        text1: "Poor data analysis, ineffective ads, low visibility",
        text2: `The brand struggled to collect and analyze data efficiently, which made it difficult to gain actionable insights into its performance. This lack of clarity prevented them from identifying key areas needing improvement. Additionally, they faced challenges in optimizing their advertising spend, leading to a lack of visibility impacting customer satisfaction, and overall sales.`,
    };

    const impact = {
        impactCards: {
            card1: {
                header: "Revenue",
                figures: 0,
                figurePrefix: "",
                figuresPostfix: "%",
                content: "Increase in overall Revenue",
            },
            card2: {
                header: "Stock-Out",
                figures: 0,
                figuresPostfix: "%",
                content: "Stock-outs were reduced, improving the consistency of their menu offerings",
            },
            card3: {
                header: "SEO",
                figures: 0,
                figuresPostfix: "%",
                content: "The brand’s search engine performance improved resulting in more organic traffic",
            },
        },
        owner_views: {
            title: "Burma Burma",
            description: `We've been using their dashboard and as a brand that's in multiple cities and scaling, it becomes important to track our metrics across different platforms all together. It helps us get a closer look on newer outlets vs same store growth gaps and how we can leverage and benchmark several things from the brands around us via their competitor benchmarking feature.`,
            name: "Chirag Chhajer",
            designation: "Co-Founder, Burma Burma",
            owner_img: burmaowner
        },
    };


    const solutions = {
        1: {
            header: "Real-Time Data and Operational Insights",
            content: ` The software offered Burma Burma daily reports that delivered critical performance metrics, including revenue generation, advertising spend, discount offers, and individual store performance. This data empowered the team to make more informed decisions and optimize their ad strategies, ultimately boosting their marketing ROI and improving overall performance.`,
            src: burmaone,
        },
        2: {
            header: "Inventory and Menu Management System",
            content:
                "Our real-time inventory tracking tool was integrated into their operations, allowing the team to monitor stock levels and align them with customer demand. This reduced stock-outs, ensuring that popular menu items were consistently available, which led to higher customer satisfaction and improved menu-to-cart conversion rates.",
            src: burmatwo,
        }

    };

    return (
        <>
            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div
                    // className="casestudy "

                    className="container-lg"
                >
                    <div
                        className="casestudy"
                        style={{ fontFamily: "Poppins , sans-serif" }}
                    >
                        <div className="main-section">
                            {/* left-main-section */}
                            <div className="left-main-section">
                                <div className="left-section-desc">
                                    Burma Burma : Centralised Aggregator management system
                                </div>
                                <div className="info-tags">
                                    {Object.keys(infoAboutCompany).map((key) => (
                                        <Infotags
                                            key={key}
                                            header={key}
                                            value={infoAboutCompany[key]}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* image */}
                            <img src={burmahome} className="image-main-section"></img>
                        </div>


                        <div className="sections">
                            <nav className="section-navbar">
                                <a href="#challenge">Challenge</a>
                                <a href="#solution">Solution</a>
                                <a href="#impact">Impact</a>
                            </nav>

                            <section id="challenge">
                                <ChallengeSection
                                    text1={challenge.text1}
                                    text2={challenge.text2}
                                />
                                {/* <Challenge/> */}
                            </section>

                            <section id="solution">
                                <SolutionSection solutions={solutions} />
                            </section>

                            <section id="impact">
                                {/* <Impact /> */}
                                <ImpactSection impact={impact} />
                            </section>

                            <div className="cardcase" ref={ref}>
                                {inView && (
                                    <div className="cardcasestudyHeader animate-from-top">
                                        Success Story
                                    </div>
                                )}

                                <CaseStudyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <SuceessStory
                    titletext="Be ahead of the curve"
                    desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
                    buttontext="Join Waitlist"
                    redirect="/contact-us"
                />
            </div>
        </>
    );
};

export default Burma;
