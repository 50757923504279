import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./navbar.css";

import "./navbar.css";

const drawerWidth = 240;
const navItems = ["home", "services", "about Us", "Blogs", "Login"];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [onClickedAboutUs, setOnClickedAboutUs] = React.useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isDropdownVisibleTwo, setDropdownVisibleTwo] = useState(false);

  const onClickHandler = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const onClickHandlerTwo = () => {
    setDropdownVisibleTwo(!isDropdownVisibleTwo);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const handleMouseEnterTwo = () => {
    setDropdownVisibleTwo(true);
  };

  const handleMouseLeaveTwo = () => {
    setDropdownVisibleTwo(false);
  };

  useEffect(() => {
    let timer;
    if (isDropdownVisible) {
      timer = setTimeout(() => {
        setDropdownVisible(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [isDropdownVisible]);

  useEffect(() => {
    let timer;
    if (isDropdownVisibleTwo) {
      timer = setTimeout(() => {
        setDropdownVisibleTwo(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [isDropdownVisibleTwo]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography
        variant="h6"
        sx={{
          my: 2,
          fontFamily: "'BC Novatica CYR Regular', sans-serif",
          color: "white",
        }}
      >
        <a href="/" style={{ color: "white" }}>
          restaverse
        </a>
      </Typography>
      <Divider />
      <List>
        <ListItem key="1" disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "white" }}>
            <ListItemText
              primary={
                <a
                  href="/"
                  style={{ color: "white", textTransform: "capitalize" }}
                >
                  Home
                </a>
              }
            />
          </ListItemButton>
        </ListItem>

        <ListItem key="2" disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "white" }}>
            <ListItemText
              primary={
                <a
                  href="/services/know-more"
                  style={{ color: "white", textTransform: "capitalize" }}
                >
                  Services
                </a>
              }
            />
          </ListItemButton>
        </ListItem>

        <ListItem key="3" disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "white" }}>
            <ListItemText
              primary={
                <Link
                  to="/services/ai-response"
                  style={{ color: "white", textTransform: "capitalize" }}
                >
                  AI Response
                </Link>
              }
            />
          </ListItemButton>
        </ListItem>

        <ListItem key="4" disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "white" }}>
            <ListItemText
              primary={
                <a
                  href="/about-us/our-team"
                  style={{ color: "white", textTransform: "capitalize" }}
                >
                  Our Team
                </a>
              }
            />
          </ListItemButton>
        </ListItem>

        <ListItem key="5" disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "white" }}>
            <ListItemText
              primary={
                <a
                  href="/about-us/join-us"
                  style={{ color: "white", textTransform: "capitalize" }}
                >
                  Join Us
                </a>
              }
            />
          </ListItemButton>
        </ListItem>

        <ListItem key="6" disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "white" }}>
            <ListItemText
              primary={
                <a
                  href="/success-stories/Greenr-cafe/"
                  style={{ color: "white", textTransform: "capitalize" }}
                >
                  Success Stories
                </a>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem key="8" disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "white" }}>
            <ListItemText
              primary={
                <a
                  href="/blogs/"
                  style={{ color: "white", textTransform: "capitalize" }}
                >
                  Blogs
                </a>
              }
            />
          </ListItemButton>
        </ListItem>

        <ListItem key="7" disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "white" }}>
            <ListItemText
              primary={
                <a
                  href="https://app.restaverse.com/login"
                  style={{
                    color: "white",
                    textTransform: "capitalize",
                    backgroundColor: "#6C41FF",
                    paddingInline: "20px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    borderRadius: "5px",
                  }}
                >
                  Login
                </a>
              }
            />
          </ListItemButton>
        </ListItem>


      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", height: "70px" }}>
      <CssBaseline />

      <AppBar component="nav" style={{ backgroundColor: "#1d0f33" }}>
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: {
                xs: "block",
                sm: "none",
                fontFamily: "'BC Novatica CYR Regular', sans-serif",
                fontWeight: "regular",
              },
            }}
          >
            <a href="/" style={{ color: "white" }}>
              restaverse
            </a>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h4"
            component="div"
            sx={{
              flexGrow: 3,
              display: {
                xs: "none",
                sm: "block",
                fontFamily: "'BC Novatica CYR Regular', sans-serif",
                fontWeight: "regular",
              },
            }}
          >
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              restaverse
            </Link>
          </Typography>

          <Box
            sx={{
              display: {
                xs: "none",
                sm: "flex",
                justifyContent: "space-around",
              },
              flexGrow: 1,
            }}
          >
            <Button
              key={1}
              sx={{
                color: "#fff",
                textTransform: "none",
                borderBottom: "3px solid transparent",
                borderRadius: "0px",
                transition: "border-bottom 0.3s ease-in-out",
                "&:hover": {
                  borderBottom: "3px solid #6C41ff",
                },
              }}
            >
              <a
                href="/"
                style={{
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  fontFamily: "Poppins , sans-serif",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                }}
              >
                Home
              </a>
            </Button>

            <Button
              key={3}
              sx={{
                color: "#fff",
                textTransform: "none",
                position: "relative",
                backgroundColor: "#1d0f33",
                borderRadius: "0px",
                borderBottom: "3px solid transparent",
                transition: "border-bottom 0.3s ease-in-out",
                "&:hover": {
                  borderBottom: "3px solid #6C41ff",
                },
              }}
              onMouseEnter={handleMouseEnterTwo}


            >
              <div className="navdropdown">
                <a
                  onClick={() => { }}
                  style={{
                    color: "white",
                    width: "100%",
                    textAlign: "center",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  Services &nbsp;
                  {isDropdownVisibleTwo ? (
                    <span>
                      <FontAwesomeIcon style={{ width: "20px" }} icon={faChevronRight} />
                    </span>
                  ) : (
                    <span>
                      <FontAwesomeIcon style={{ width: "20px" }} icon={faChevronDown} />
                    </span>
                  )}

                </a>
                <ul
                  className="navdropdowncontent"
                  style={{
                    position: "absolute",
                    top: "130%",
                    borderRadius: "5px",
                    color: "black",
                    paddingInline: "0px",
                    backgroundColor: "white",
                    display: isDropdownVisibleTwo ? "block" : "none",
                  }}
                  onMouseEnter={() => { }}
                  onMouseLeave={handleMouseLeaveTwo}
                >
                  <li
                    className="knowmore"
                    style={{
                      textAlign: "left",
                      paddingInline: "17px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      width: "115px",
                      borderRadius: "0px",
                    }}
                  >
                    <a
                      href="/services/know-more"
                      style={{
                        textAlign: "left",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "1rem",
                        color: "black",
                        whiteSpace: "nowrap",
                        marginLeft: "-10px",
                      }}
                    >
                      Know More
                    </a>
                  </li>
                  <li
                    className="airesponse"
                    style={{
                      textAlign: "left",
                      paddingInline: "17px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      width: "115px",
                      borderRadius: "0px",
                    }}
                  >
                    <a
                      href="/services/ai-response"
                      style={{
                        textAlign: "left",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "1rem",
                        color: "black",
                        whiteSpace: "nowrap",
                        marginLeft: "-10px",
                      }}
                    >
                      AI Response
                    </a>
                  </li>
                </ul>
              </div>
            </Button>

            <Button
              key={4}
              sx={{
                color: "#fff",
                textTransform: "none",
                position: "relative",
                backgroundColor: "#1d0f33",
                borderRadius: "0px",
                borderBottom: "3px solid transparent",
                transition: "border-bottom 0.3s ease-in-out",
                "&:hover": {
                  borderBottom: "3px solid #6C41ff",
                },
              }}
              onMouseEnter={handleMouseEnter}

            >
              <div className="navdropdown">
                <a
                  onClick={() => { }}
                  style={{
                    color: "white",
                    width: "100%",
                    textAlign: "center",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  About Us &nbsp;
                  {isDropdownVisible ? (
                    <span>
                      <FontAwesomeIcon style={{ width: "20px" }} icon={faChevronRight} />
                    </span>
                  ) : (
                    <span>
                      <FontAwesomeIcon style={{ width: "20px" }} icon={faChevronDown} />
                    </span>
                  )}
                </a>
                <ul
                  className="navdropdowncontent"
                  style={{
                    position: "absolute",
                    top: "130%",
                    borderRadius: "5px",
                    color: "black",
                    paddingInline: "0px",
                    backgroundColor: "white",
                    display: isDropdownVisible ? "block" : "none",
                  }}
                  onMouseEnter={() => { }}
                  onMouseLeave={handleMouseLeave}
                >
                  <li
                    className="ourteam"
                    style={{
                      textAlign: "left",
                      paddingInline: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                  >
                    <a
                      href="/about-us/our-team"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "1rem",
                        color: "black",
                        whiteSpace: "nowrap",
                        marginLeft: "-10px",
                      }}
                    >
                      Our Team
                    </a>
                  </li>

                  <li
                    className="joinus"
                    style={{
                      textAlign: "left",
                      paddingInline: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                  >
                    <a
                      href="/about-us/join-us"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "1rem",
                        color: "black",
                        marginLeft: "-10px",
                      }}
                    >
                      Join Us
                    </a>
                  </li>
                </ul>
              </div>
            </Button>
            <Button
              key={2}
              sx={{
                color: "#fff",
                textTransform: "none",
                borderRadius: "0px",
                borderBottom: "3px solid transparent", // Set a transparent border initially
                transition: "border-bottom 0.3s ease-in-out", // Add a transition for a smooth effect
                "&:hover": {
                  borderBottom: "3px solid #6C41ff", // Change the border color on hover
                },
              }}
            >
              <a
                href="/success-stories/Greenr-cafe/"
                style={{
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  fontFamily: "Poppins , sans-serif",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                }}
              >
                Success Stories
              </a>
            </Button>
            <Button
              key={1}
              sx={{
                color: "#fff",
                textTransform: "none",
                borderBottom: "3px solid transparent",
                borderRadius: "0px",
                transition: "border-bottom 0.3s ease-in-out",
                "&:hover": {
                  borderBottom: "3px solid #6C41ff",
                },
              }}
            >
              <a
                href="/blogs"
                style={{
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  fontFamily: "Poppins , sans-serif",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                }}
              >
                Blogs
              </a>
            </Button>
            <Button
              key={4}
              sx={{
                color: "#fff",
                textTransform: "none",

                backgroundColor: "#6C41FF",
                paddingInline: "20px",
              }}
            >
              <a
                href="https://app.restaverse.com/login"
                style={{
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  fontFamily: "Poppins , sans-serif",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                }}
              >
                {/* <span style={{ textTransform: "uppercase" }}>
                  {item.charAt(0)}
                </span>
                {item.slice(1)} */}
                Login
              </a>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#1d0f33",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
