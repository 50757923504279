import { useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './cscarousel.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ufclogo from "../../images/CaseStudy/casestudylogos/ufc.webp";
import silverbeach from "../../images/CaseStudy/casestudylogos/sbfc.webp";
import greenr from "../../images/CaseStudy/greenr/greenrlogo.webp";
import goma from "../../images/CaseStudy/goma/gomalogo.webp";
import shm from "../../images/CaseStudy/shm/shmlogo.webp";
import meetha from "../../images/CaseStudy/meetha/meethalogo.webp";
import apsara from "../../images/CaseStudy/casestudylogos/apsara.png";
import breadbar from "../../images/CaseStudy/casestudylogos/breadbar.png";
import burgerama from "../../images/CaseStudy/casestudylogos/burgerama.png";
import burmaburma from "../../images/CaseStudy/casestudylogos/burmaburma.png";
import doughtherapy from "../../images/CaseStudy/casestudylogos/doughtherapy.png";
import eatverse from "../../images/CaseStudy/casestudylogos/eatverse.png";
import magst from "../../images/CaseStudy/casestudylogos/magst.png";
import maiz from "../../images/CaseStudy/casestudylogos/maiz.png";
import meemees from "../../images/CaseStudy/casestudylogos/meemees.png";
import miam from "../../images/CaseStudy/casestudylogos/miam.png";
import ninoburger from "../../images/CaseStudy/casestudylogos/ninoburgers.png";
import rb from "../../images/CaseStudy/casestudylogos/r&b.png";


const NextArrow = ({ onClick }) => {
    return (
        <div className="arrow prev" onClick={onClick}>
            <FaChevronRight />
        </div>
    );
};


const PrevArrow = ({ onClick }) => {
    return (
        <div className="arrow next" onClick={onClick}>
            <FaChevronLeft />
        </div>
    );
};

const CasestudyCardCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        rows: 2,
        autoplay: true,
        swipeToSlide: true,
        autoplaySpeed: 2000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [

            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 2
                }
            },

            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            }

        ]

    };


    return (
        <div className="slider-container">
            <Slider {...settings}>

                <div className="cases">
                    <a href={"/success-stories/United-farmers-creamery/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={ufclogo}
                                    alt={"UFC"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue growth via better search engine optimization"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/Silver-beach-cafe/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                              
                                <img
                                    src={silverbeach}
                                    alt={"Silver Beach"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue growth via revamping brand positioning"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>

                <div className="cases">
                    <a href={"/success-stories/Greenr-cafe/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={greenr}
                                    alt={"Greenr Cafe"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Customer Engagement Enhancement through Strategic Menu Optimization"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/Goma/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={goma}
                                    alt={"UFC"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue growth via introducing online delivery"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/Meetha-by-radisson/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={meetha}
                                    alt={"meetha"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue growth via through effective SEO strategies"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/SHM/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={shm}
                                    alt={"shm"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue growth via menu enhancement strategies"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/mag-st/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={magst}
                                    alt={"magst"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue Growth  via Same Store Sales Growth"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/miam/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={miam}
                                    alt={"maiz"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue Growth via Same Store Sales Growth"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/nino-burgers/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={ninoburger}
                                    alt={"nino burgers"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue Growth via dishwise feedback analysis"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/ribbons-and-balloons/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={rb}
                                    alt={"r&b"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue Growth via stock out issue analysis and SSSG"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/bread-bar/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={breadbar}
                                    alt={"bread bar"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue Growth via Menu Optimisation "}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/dough-therapy/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={doughtherapy}
                                    alt={"doughtherapy"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Launching a premium gourmet handcrafted pizza brand"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/maiz-mexican/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={maiz}
                                    alt={"maiz"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Centralised reviews management system "}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/burma-burma/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={burmaburma}
                                    alt={"burma"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Centralised Aggregator management system"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/apsara-ice-creams/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={apsara}
                                    alt={"apsara"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Centralised review management system"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/vision-foods/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={eatverse}
                                    alt={"vision food"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Centralised reviews  and complaints management system"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/meemees/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={meemees}
                                    alt={"meemees"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Centralised out of stock checker"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/success-stories/burgerama/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={burgerama}
                                    alt={"burgerama"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Centralised reviews  and complaints management system"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
            </Slider>
        </div>
    );
};

export default CasestudyCardCarousel;
