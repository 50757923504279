import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import maizhome from "../../images/CaseStudy/maiz/maizhome.png";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import miazowner from "../../images/CaseStudy/maiz/maizfounder.png";
import maizone from '../../images/CaseStudy/maiz/Miaz 1.png';
import maiztwo from '../../images/CaseStudy/maiz/Maiz 2.png';
import maizthree from '../../images/CaseStudy/maiz/maizthree.png';



const Maiz = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
    }, []);


    const infoAboutCompany = {
        Brand: `Maiz`,
        Platform: "Zomato & Swiggy",
        Cuisine: "Mexican",
        Location: "Mumbai",
        AOV: "Mid",
        "Target Audience": "Mass Premium",
    };

    const challenge = {
        text1: "Unresolved reviews, inconsistent branding, declining revenue",
        text2: `Maiz, a beloved restaurant brand known for its innovative fusion cuisine, was struggling to maintain a strong online presence. Negative reviews were often left unresolved, damaging customer trust and impacting its overall ratings on aggregator platforms. Additionally, the brand's communication lacked consistency across platforms, creating a fragmented identity. These challenges not only affected Maiz's reputation but also led to a noticeable dip in customer footfall and revenue.`,
    };

    const impact = {
        impactCards: {
            card1: {
                header: "Engagement",
                figures: 0,
                figurePrefix: "",
                figuresPostfix: "%",
                content: "Increase in positive reviews and a decrease in unresolved complaints",
            },
            card2: {
                header: "SEO",
                figures: 0,
                figuresPostfix: "%",
                content: "More visibility on search engines and aggregator platforms, attracting new customers.",
            },
            card3: {
                header: "Efficiency",
                figures: 0,
                figuresPostfix: "%",
                content: "Saved time for the brand",
            },
        },
        owner_views: {
            title: "Maiz",
            description: `We've been with restaverse as one of their 1st clients on the platform and we love their customer reputation and refunds dashboards as it helps us consolidate the overall brand score, close internal loops, assign issues and do the right mapping and root cause analysis (RCA). They're fairly swift to respond and help us out with whatever issues we are facing. Their speed on getting things closed is what sets them apart.`,
            name: "Veer Bhartiya",
            designation: "Founder, Maiz",
            owner_img: miazowner
        },
    };


    const solutions = {
        1: {
            header: "Centralized Review Management",
            content: `We provided Maiz with a powerful, centralized review management system that streamlined the process of monitoring and responding to customer reviews across multiple platforms. This single-dashboard solution ensured that no feedback was missed, enabling the Maiz team to address customer concerns promptly and effectively.`,
            src: maizone,
        },
        2: {
            header: "Data-Driven Operational Improvements",
            content:
                "We went beyond managing reviews by analyzing trends in customer feedback to provide actionable operational insights. These insights helped Maiz tackle key challenges, such as reducing wait times during peak hours, ultimately improving the overall dining experience and customer satisfaction.",
            src: maiztwo,
        },
        3: {
            header: "Personalized Responses",
            content:
                "A dedicated ORM team crafted thoughtful and personalized responses to each review, reinforcing customer trust and satisfaction. This not only helped resolve complaints effectively but also turned them into opportunities for customer recovery. Positive reviews were celebrated with gratitude, creating moments that strengthened customer loyalty.",
            src: maizthree,
        }

    };

    return (
        <>
            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div
                    // className="casestudy "

                    className="container-lg"
                >
                    <div
                        className="casestudy"
                        style={{ fontFamily: "Poppins , sans-serif" }}
                    >
                        <div className="main-section">
                            {/* left-main-section */}
                            <div className="left-main-section">
                                <div className="left-section-desc">
                                    Maiz Mexican: Centralised reviews management system
                                </div>
                                <div className="info-tags">
                                    {Object.keys(infoAboutCompany).map((key) => (
                                        <Infotags
                                            key={key}
                                            header={key}
                                            value={infoAboutCompany[key]}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* image */}
                            <img src={maizhome} className="image-main-section"></img>
                        </div>


                        <div className="sections">
                            <nav className="section-navbar">
                                <a href="#challenge">Challenge</a>
                                <a href="#solution">Solution</a>
                                <a href="#impact">Impact</a>
                            </nav>

                            <section id="challenge">
                                <ChallengeSection
                                    text1={challenge.text1}
                                    text2={challenge.text2}
                                />
                                {/* <Challenge/> */}
                            </section>

                            <section id="solution">
                                <SolutionSection solutions={solutions} />
                            </section>

                            <section id="impact">
                                {/* <Impact /> */}
                                <ImpactSection impact={impact} />
                            </section>

                            <div className="cardcase" ref={ref}>
                                {inView && (
                                    <div className="cardcasestudyHeader animate-from-top">
                                        Success Story
                                    </div>
                                )}

                                <CaseStudyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <SuceessStory
                    titletext="Be ahead of the curve"
                    desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
                    buttontext="Join Waitlist"
                    redirect="/contact-us"
                />
            </div>
        </>
    );
};

export default Maiz;
