import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import visionhome from "../../images/CaseStudy/visionfoods/eatversehome.png";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import visionfoodsowner from "../../images/CaseStudy/visionfoods/eatversefounder.png";
import visionone from '../../images/CaseStudy/visionfoods/Eatverse 1.png';
import visiontwo from '../../images/CaseStudy/visionfoods/Eatverse 2.png';


const Visionfood = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
    }, []);


    const infoAboutCompany = {
        Brand: `Vision Foods`,
        Platform: "Zomato & Swiggy",
        Cuisine: "Cafe",
        Location: "Kolkata",
        AOV: "Mid",
        "Target Audience": "Mass",
    };

    const challenge = {
        text1: "Poor data insights, inefficient inventory, low ROI",
        text2: `Vision Foods, a leading brand based in Kolkata, faced significant operational and marketing hurdles. The absence of a streamlined system for collecting and analyzing performance data left the team unable to identify areas requiring improvement. This lack of actionable insights also made it challenging to optimize advertising spend effectively, resulting in suboptimal ROI. Additionally, inefficiencies in inventory management led to stock-outs of popular items, negatively impacting customer satisfaction and overall sales. Vision Foods needed a comprehensive solution to address these critical issues and regain operational clarity.`,
    };

    const impact = {
        impactCards: {
            card1: {
                header: "Engagement",
                figures: 0,
                figurePrefix: "",
                figuresPostfix: "%",
                content: "Increase in positive reviews and a decrease in unresolved complaints.",
            },
            card2: {
                header: "SEO",
                figures: 0,
                figuresPostfix: "%",
                content: "More visibility on search engines and aggregator platforms, attracting new customers.",
            },
            card3: {
                header: "Efficiency",
                figures: 0,
                figuresPostfix: "%",
                content: "Saved time for the brand",
            },
        },
        owner_views: {
            title: "Vision Foods",
            description: `We run multiple brands across our kitchens in Kolkata and having a centralised customer feedback tool was necessary. Before restaverse we were on another tool that did the basic analysis, but now we are able to correlate several complexities around new, repeat users and the dish wise feedback. Also, helps us know the item count ratings, etc that helps us on the overall store mapping for product feedback, reviews, complaints, etc.`,
            name: "Aadarsh Choudhary ",
            designation: "Co-Founder, Eatverse",
            owner_img: visionfoodsowner
        },
    };


    const solutions = {
        1: {
            header: "Real-Time Data and Operational Insights",
            content: `The dashboard provided Vision Foods with daily performance reports covering key metrics such as revenue generation, advertising spend, discount utilization, and store-specific performance. This real-time data enabled the team to make informed decisions and adapt their strategies swiftly. By analyzing these metrics, Vision Foods optimized its advertising campaigns, significantly enhancing marketing ROI. The actionable insights also facilitated the identification of growth opportunities and performance bottlenecks, empowering the brand to improve its overall efficiency.`,
            src: visionone,
        },
        2: {
            header: "Inventory and Menu Management System",
            content:
                "The integration of our real-time inventory tracking system revolutionized their stock management. With continuous monitoring of inventory levels, the team could align stock availability with customer demand, ensuring popular items remained consistently in stock. This not only minimized stock-outs but also elevated menu-to-cart conversion rates, leading to higher customer satisfaction. Furthermore, the system supported smarter menu management by highlighting trends and preferences, allowing Vision Foods to tailor its offerings to maximize appeal.",
            src: visiontwo,
        }
    };

    return (
        <>
            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div
                    // className="casestudy "

                    className="container-lg"
                >
                    <div
                        className="casestudy"
                        style={{ fontFamily: "Poppins , sans-serif" }}
                    >
                        <div className="main-section">
                            {/* left-main-section */}
                            <div className="left-main-section">
                                <div className="left-section-desc">
                                    Vision Foods : Centralised reviews and complaints management system
                                </div>
                                <div className="info-tags">
                                    {Object.keys(infoAboutCompany).map((key) => (
                                        <Infotags
                                            key={key}
                                            header={key}
                                            value={infoAboutCompany[key]}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* image */}
                            <img src={visionhome} className="image-main-section"></img>
                        </div>


                        <div className="sections">
                            <nav className="section-navbar">
                                <a href="#challenge">Challenge</a>
                                <a href="#solution">Solution</a>
                                <a href="#impact">Impact</a>
                            </nav>

                            <section id="challenge">
                                <ChallengeSection
                                    text1={challenge.text1}
                                    text2={challenge.text2}
                                />
                                {/* <Challenge/> */}
                            </section>

                            <section id="solution">
                                <SolutionSection solutions={solutions} />
                            </section>

                            <section id="impact">
                                {/* <Impact /> */}
                                <ImpactSection impact={impact} />
                            </section>

                            <div className="cardcase" ref={ref}>
                                {inView && (
                                    <div className="cardcasestudyHeader animate-from-top">
                                        Success Story
                                    </div>
                                )}

                                <CaseStudyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <SuceessStory
                    titletext="Be ahead of the curve"
                    desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
                    buttontext="Join Waitlist"
                    redirect="/contact-us"
                />
            </div>
        </>
    );
};

export default Visionfood;
