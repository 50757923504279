import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import doughtherapyhome from "../../images/CaseStudy/doughtherapy/doughtherapyhome.png";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import doughtherapyowner from "../../images/CaseStudy/doughtherapy/dtfounder.jpg";
import doughtherapyone from '../../images/CaseStudy/doughtherapy/Dough Therapy 1.png';
import doughtherapytwo from '../../images/CaseStudy/doughtherapy/TDT 2.png';
import doughtherapythree from '../../images/CaseStudy/doughtherapy/doughtherapythree.png';
import doughtherapyfour from '../../images/CaseStudy/doughtherapy/doughtherapyfour.png';
import doughtherapyfive from '../../images/CaseStudy/doughtherapy/doughtherapyfive.png';


const Doughtherapy = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
    }, []);


    const infoAboutCompany = {
        Brand: `The Dough Therapy`,
        Platform: "Zomato & Swiggy",
        Cuisine: "Pizza",
        Location: "Mumbai",
        AOV: "Mid",
        "Target Audience": "Mass Premium",
    };

    const challenge = {
        text1: "Limited audience, cold deliveries, weak marketing",
        text2: `Dough Therapy, a pure vegetarian gourmet pizza brand, faced challenges in scaling its business due to a limited target audience. In Mumbai, where 70% of pizza revenue comes from non-vegetarian options, their niche positioning restricted their reach. Additionally, their operational inefficiencies impacted customer satisfaction. Pizzas were often delivered cold due to inadequate packaging, leading to negative user experiences. The brand also lacked optimized marketing strategies to achieve sustainable growth and capitalize on emerging opportunities.`,
    };

    const impact = {
        impactCards: {
            card1: {
                header: "Revenue",
                figures: 38,
                figurePrefix: "",
                figuresPostfix: "%",
                content: "Increase in overall revenue",
            },
            card2: {
                header: "Conversion",
                figures: 50,
                figuresPostfix: "%",
                content: "Increase in menu-to-order conversions",
            },
            card3: {
                header: "ROAS",
                figures: 40,
                figuresPostfix: "%",
                content: "Increase in ROAS",
            },
        },
        owner_views: {
            title: "The Dough Therapy",
            description: `We have been with restaverse since we were at 3 outlets, and in these 3 years they have helped us identify the right locations to tap into and scale faster. They have helped us deliver better Same Store Sales Growth(SSSG) and helped us break-even on the new stores faster.Their grip on the industry, stakeholder management and attention to detail is why I would strongly recommend them.`,
            name: "Abhishek Dedhia",
            designation: "Co-Founder, The Dough Therapy",
            owner_img: doughtherapyowner
        },
    };


    const solutions = {
        1: {
            header: "Expanding the Audience with a New Sub-Brand",
            content: `We collaborated with Dough Therapy to launch Slices by Dough Therapy, a brand aimed at capturing a broader market. By offering single slices designed for on-the-go consumption, the brand targeted a new segment of customers, increasing accessibility and appeal.`,
            src: doughtherapyone,
        },
        2: {
            header: "Enhancing Packaging for Better Delivery Experience",
            content:
                "To tackle the issue of cold pizzas, we proposed an upgrade to their packaging, ensuring pizzas retained their heat and freshness during delivery. This significantly improved customer satisfaction and reduced complaints.",
            src: doughtherapytwo,
        },
        3: {
            header: "Driving Growth Through Marketing and Innovation",
            content:
                "SEO and Performance Marketing Optimization: We focused on year-on-year growth by refining their SEO strategies and performance marketing campaigns, increasing their online visibility and conversions.",
            src: doughtherapythree,
        },
        4: {
            header: "New Product Introductions",
            content:
                "We ideated and helped launch innovative pizza variants, which now contribute to 12-13% of the company’s overall revenue.",
            src: doughtherapyfour,
        },
        5: {
            header: "Late Night Activation",
            content:
                "Activate late night cravings by optimising the operating hours such that the revenue per hour grows. ",
            src: doughtherapyfive,
        }

    };

    return (
        <>
            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div
                    // className="casestudy "

                    className="container-lg"
                >
                    <div
                        className="casestudy"
                        style={{ fontFamily: "Poppins , sans-serif" }}
                    >
                        <div className="main-section">
                            {/* left-main-section */}
                            <div className="left-main-section">
                                <div className="left-section-desc">
                                    The Dough Therapy: Launching a gourmet pizza brand
                                </div>
                                <div className="info-tags">
                                    {Object.keys(infoAboutCompany).map((key) => (
                                        <Infotags
                                            key={key}
                                            header={key}
                                            value={infoAboutCompany[key]}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* image */}
                            <img src={doughtherapyhome} className="image-main-section"></img>
                        </div>


                        <div className="sections">
                            <nav className="section-navbar">
                                <a href="#challenge">Challenge</a>
                                <a href="#solution">Solution</a>
                                <a href="#impact">Impact</a>
                            </nav>

                            <section id="challenge">
                                <ChallengeSection
                                    text1={challenge.text1}
                                    text2={challenge.text2}
                                />
                                {/* <Challenge/> */}
                            </section>

                            <section id="solution">
                                <SolutionSection solutions={solutions} />
                            </section>

                            <section id="impact">
                                {/* <Impact /> */}
                                <ImpactSection impact={impact} />
                            </section>

                            <div className="cardcase" ref={ref}>
                                {inView && (
                                    <div className="cardcasestudyHeader animate-from-top">
                                        Success Story
                                    </div>
                                )}

                                <CaseStudyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <SuceessStory
                    titletext="Be ahead of the curve"
                    desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
                    buttontext="Join Waitlist"
                    redirect="/contact-us"
                />
            </div>
        </>
    );
};

export default Doughtherapy;
