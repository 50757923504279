import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import breadbarhome from "../../images/CaseStudy/breadbar/breadbarhome.png";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import breadbarowner from "../../images/CaseStudy/breadbar/breadbarfounder.jpg";
import breadbarone from '../../images/CaseStudy/breadbar/Bread Bar 1.png';
import breadbartwo from '../../images/CaseStudy/breadbar/Bread Bar 2.png';
import breadbarthree from '../../images/CaseStudy/breadbar/breadbarthree.png';


const BreadBar = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
    }, []);


    const infoAboutCompany = {
        Brand: `Bread Bar`,
        Platform: "Zomato & Swiggy",
        Cuisine: "Cafe",
        Location: "Mumbai",
        AOV: "High",
        "Target Audience": "Premium",
    };

    const challenge = {
        text1: "Limited menu, low delivery revenue, tough competition.",
        text2: `Bread Bar started as a niche café specializing in bread-based items such as artisan loaves and desserts. While the offerings were unique, the menu’s limited scope made it difficult for them to compete with larger café-style brands like Silver Beach Cafe, Grandmama’s, and Mag Street. Moreover, their delivery revenue was modest, averaging ₹6-7 lakhs per month. To thrive in a highly competitive market, Bread Bar needed a broader menu and a strategic approach to pricing and discounting to attract a larger customer base and drive higher revenue.`,
    };

    const impact = {
        impactCards: {
            card1: {
                header: "Revenue",
                figures: 3,
                figurePrefix: "",
                figuresPostfix: "X",
                content: "Increase overall revenue",
            },
            card2: {
                header: "Menu Opens",
                figures: 2,
                figuresPostfix: "X",
                content: "Increase in menu opens",
            },
            card3: {
                header: "Orders Conversion",
                figures: 20,
                figuresPostfix: "%",
                content: "Increase in menu-to-order conversions",
            },
        },
        owner_views: {
            title: "Bread Bar",
            description: `We are a standalone restaurant in Chembur, Mumbai. I come from a culinary background and hence the several aggregator KPIs were not my strongest suit. Thanks to team restaverse, I can focus on the kitchen and they take care of the online food delivery platforms. We've seen better growth than our category over a year's time that we've worked with the restaverse team.`,
            name: "Rachi Gupta",
            designation: "Founder, The Bread Bar",
            owner_img: breadbarowner
        },
    };


    const solutions = {
        1: {
            header: "Menu Expansion",
            content: `The menu was redesigned to include a diverse range of café-style dishes such as pizza, pasta, and flat dishes, while retaining their signature bread-focused offerings. This broadened their appeal to a wider demographic, making the menu competitive with leading brands like Silver Beach Cafe and Grandmama’s.`,
            src: breadbarone,
        },
        2: {
            header: "Discounting Strategy",
            content:
                "A robust discounting and pricing strategy was introduced to drive both dine-in and delivery traffic. Targeted offers were crafted to attract new customers, boost repeat orders, and foster loyalty, all while ensuring profitability.",
            src: breadbartwo,
        },
        3: {
            header: "Operational Optimization",
            content:
                "We worked closely with the Bread Bar team to streamline their operational workflow, ensuring seamless execution of the expanded menu and maintaining consistent quality across all offerings.",
            src: breadbarthree,
        }

    };

    return (
        <>
            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div
                    // className="casestudy "

                    className="container-lg"
                >
                    <div
                        className="casestudy"
                        style={{ fontFamily: "Poppins , sans-serif" }}
                    >
                        <div className="main-section">
                            {/* left-main-section */}
                            <div className="left-main-section">
                                <div className="left-section-desc">
                                    Bread Bar : Revenue Growth via Menu Optimisation
                                </div>
                                <div className="info-tags">
                                    {Object.keys(infoAboutCompany).map((key) => (
                                        <Infotags
                                            key={key}
                                            header={key}
                                            value={infoAboutCompany[key]}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* image */}
                            <img src={breadbarhome} className="image-main-section"></img>
                        </div>


                        <div className="sections">
                            <nav className="section-navbar">
                                <a href="#challenge">Challenge</a>
                                <a href="#solution">Solution</a>
                                <a href="#impact">Impact</a>
                            </nav>

                            <section id="challenge">
                                <ChallengeSection
                                    text1={challenge.text1}
                                    text2={challenge.text2}
                                />
                                {/* <Challenge/> */}
                            </section>

                            <section id="solution">
                                <SolutionSection solutions={solutions} />
                            </section>

                            <section id="impact">
                                {/* <Impact /> */}
                                <ImpactSection impact={impact} />
                            </section>

                            <div className="cardcase" ref={ref}>
                                {inView && (
                                    <div className="cardcasestudyHeader animate-from-top">
                                        Success Story
                                    </div>
                                )}

                                <CaseStudyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <SuceessStory
                    titletext="Be ahead of the curve"
                    desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
                    buttontext="Join Waitlist"
                    redirect="/contact-us"
                />
            </div>
        </>
    );
};

export default BreadBar;
