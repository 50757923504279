import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import magsthome from "../../images/CaseStudy/magst/magsthome.png";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import mgstowner from "../../images/CaseStudy/magst/magstfounder.png";
import magstone from '../../images/CaseStudy/magst/Mag St.1.png';
import magsttwo from '../../images/CaseStudy/magst/Mag St. 2.png';
import magstthree from '../../images/CaseStudy/magst/magstthree.png';



const Mgst = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
    }, []);


    const infoAboutCompany = {
        Brand: `Mag St.`,
        Platform: "Zomato & Swiggy",
        Cuisine: "Cafe",
        Location: "Mumbai",
        AOV: "High",
        "Target Audience": "Premium",
    };

    const challenge = {
        text1: `Low visibility, inefficiencies, lost revenue`,
        text2: `Mag Street faced significant hurdles in unlocking its true revenue potential. Their outlet struggled with low visibility on aggregator platforms, making it hard for customers to find and order from them. Operational inefficiencies further compounded the problem. The store frequently stayed offline, rejected incoming orders, and failed to update inventory accurately. These missteps led to declining conversions, frustrated customers, and stagnant revenue growth. As a result, the outlet was unable to capitalize on the demand available in their market.`,
    };

    const impact = {
        impactCards: {
            card1: { 
                header: "Revenue",
                figures: 70,
                figurePrefix: "",
                figuresPostfix: "%",
                content: "Increase in overall Revenue",
            },
            card2: {
                header: "Menu Opens",
                figures: 50,
                figuresPostfix: "%",
                content: "Increase in Menu Opens",
            },
            card3: {
                header: "Orders Conversion",
                figures: 28,
                figuresPostfix: "%",
                content: "Increase in menu-to-order conversions",
            },
        },
        owner_views: {
            title: "Mag St.",
            description: `We've been with restaverse for about a year odd, and in this time we've not only automated a bunch of our daily tasks linked to operations, revenues, review and user sentiment online, but also managed to get clear insights around customer feedback, supply chain. The team is fairly prompt, data backed and provide insights that help us with the outcomes we require and that helps us focus on the key things our business needs`,
            name: "Gauri Devidayal",
            designation: "Co-Founder, Food Matters.",
            owner_img: mgstowner
        },
    };


    const solutions = {
        1: {
            header: "Boosting Platform Visibility",
            content: `Platform visibility was enhanced using advanced SEO techniques to improve rankings on aggregator platforms. This optimization ensured the outlet appeared prominently in customer searches and recommendations, resulting in increased exposure and higher organic traffic.`,
            src: magstone,
        },
        2: {
            header: "Operational Training and Best Practices",
            content:
                "The team was trained on critical operational practices, including maintaining consistent store availability to maximize potential orders, managing inventory accurately to minimize order rejections, and leveraging performance metrics to identify and address key areas for improvement.",
            src: magsttwo,
        },
        3: {
            header: "Continuous Monitoring and Real-Time Feedback",
            content:
                "A system was established to monitor key performance metrics continuously. Real-time feedback and actionable insights allowed operational inefficiencies to be addressed promptly, ensuring consistent improvements in performance and efficiency. This multi-faceted approach optimized operations, improved customer satisfaction, and delivered sustainable growth.",
            src: magstthree,
        }

    };

    return (
        <>
            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div
                    // className="casestudy "

                    className="container-lg"
                >
                    <div
                        className="casestudy"
                        style={{ fontFamily: "Poppins , sans-serif" }}
                    >
                        <div className="main-section">
                            {/* left-main-section */}
                            <div className="left-main-section">
                                <div className="left-section-desc">
                                    Mag St. : Revenue Growth  via Same Store Sales Growth
                                </div>
                                <div className="info-tags">
                                    {Object.keys(infoAboutCompany).map((key) => (
                                        <Infotags
                                            key={key}
                                            header={key}
                                            value={infoAboutCompany[key]}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* image */}
                            <img src={magsthome} className="image-main-section"></img>
                        </div>


                        <div className="sections">
                            <nav className="section-navbar">
                                <a href="#challenge">Challenge</a>
                                <a href="#solution">Solution</a>
                                <a href="#impact">Impact</a>
                            </nav>

                            <section id="challenge">
                                <ChallengeSection
                                    text1={challenge.text1}
                                    text2={challenge.text2}
                                />
                                {/* <Challenge/> */}
                            </section>

                            <section id="solution">
                                <SolutionSection solutions={solutions} />
                            </section>

                            <section id="impact">
                                {/* <Impact /> */}
                                <ImpactSection impact={impact} />
                            </section>

                            <div className="cardcase" ref={ref}>
                                {inView && (
                                    <div className="cardcasestudyHeader animate-from-top">
                                        Success Story
                                    </div>
                                )}

                                <CaseStudyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <SuceessStory
                    titletext="Be ahead of the curve"
                    desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
                    buttontext="Join Waitlist"
                    redirect="/contact-us"
                />
            </div>
        </>
    );
};

export default Mgst;
