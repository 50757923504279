import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import miamhome from "../../images/CaseStudy/miam/miamhome.png";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import miamowner from "../../images/CaseStudy/miam/Akshay Handa & Bani Nanda - Miam Co-founders.webp";
import miamone from '../../images/CaseStudy/miam/Miam 1.png';
import miamtwo from '../../images/CaseStudy/miam/Miam 2.png';


const Miam = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
    }, []);


    const infoAboutCompany = {
        Brand: `Miam`,
        Platform: "Zomato & Swiggy",
        Cuisine: "Desserts",
        Location: "Delhi",
        AOV: "High",
        "Target Audience": "Premium",
    };

    const challenge = {
        text1: "Underutilized marketing, poor product performance.",
        text2: `Miam, a gourmet French patisserie with two stores in Delhi and CR, faced stagnating revenue despite already spending 5% of their overall revenues on marketing. Their stores were #1 in their category and hence felt they are doing well. However, despite their marketing efforts, they weren't fully optimizing their SEO or performance marketing to its potential, which limited their ability to scale and capture more customers. Additionally, some products weren’t performing well on delivery platforms, leading to poor ratings from customers, affecting overall satisfaction.`,
    };

    const impact = {
        impactCards: {
            card1: {
                header: "Revenue",
                figures: 2,
                figurePrefix: "",
                figuresPostfix: "X",
                content: "Increase in overall revenue",
            },
            card2: {
                header: "Menu Opens",
                figures: 70,
                figuresPostfix: "%",
                content: "Increase in Menu Opens",
            },
            card3: {
                header: "ROAS",
                figures: 2,
                figuresPostfix: "%",
                content: "Increase in ROAS",
            },
        },
        owner_views: {
            title: "Miam",
            description: `We've been with restaverse for over a year, and their tool has helped us with insights especially around same store growth and helped us grow multi-fold by leveraging our digital investments better. We did not know our full potential until we met them, we were doing well, but did not know any better, we're glad to have partnered with team restaverse that helps us with all digital hand-holdings on the right platforms.`,
            name: "Akshay Handa",
            designation: "Co-Founder, Miam",
            owner_img: miamowner
        },
    };


    const solutions = {
        1: {
            header: "Optimized SEO and Performance Marketing",
            content: `existing marketing budget was maintained, but resources were strategically allocated to optimize SEO efforts and enhance performance marketing campaigns. This resulted in improved online visibility and increased customer engagement.`,
            src: miamone,
        },
        2: {
            header: "Enhanced Product Development for Delivery",
            content: "The team focused on refining and adjusting products that received negative feedback on delivery platforms. These improvements were aimed at enhancing product quality and presentation, leading to better customer satisfaction and higher ratings.",
            src: miamtwo,
        }
    };

    return (
        <>
            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div
                    // className="casestudy "

                    className="container-lg"
                >
                    <div
                        className="casestudy"
                        style={{ fontFamily: "Poppins , sans-serif" }}
                    >
                        <div className="main-section">
                            {/* left-main-section */}
                            <div className="left-main-section">
                                <div className="left-section-desc">
                                    Miam : Revenue Growth via Same Store Sales Growth
                                </div>
                                <div className="info-tags">
                                    {Object.keys(infoAboutCompany).map((key) => (
                                        <Infotags
                                            key={key}
                                            header={key}
                                            value={infoAboutCompany[key]}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* image */}
                            <img src={miamhome} className="image-main-section"></img>
                        </div>


                        <div className="sections">
                            <nav className="section-navbar">
                                <a href="#challenge">Challenge</a>
                                <a href="#solution">Solution</a>
                                <a href="#impact">Impact</a>
                            </nav>

                            <section id="challenge">
                                <ChallengeSection
                                    text1={challenge.text1}
                                    text2={challenge.text2}
                                />
                                {/* <Challenge/> */}
                            </section>

                            <section id="solution">
                                <SolutionSection solutions={solutions} />
                            </section>

                            <section id="impact">
                                {/* <Impact /> */}
                                <ImpactSection impact={impact} />
                            </section>

                            <div className="cardcase" ref={ref}>
                                {inView && (
                                    <div className="cardcasestudyHeader animate-from-top">
                                        Success Story
                                    </div>
                                )}

                                <CaseStudyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <SuceessStory
                    titletext="Be ahead of the curve"
                    desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
                    buttontext="Join Waitlist"
                    redirect="/contact-us"
                />
            </div>
        </>
    );
};

export default Miam;
