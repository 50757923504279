import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Routes,
  useLocation
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { AppProvider, useAppContext } from "./Context/notification-context";
import { GoogleOAuthProvider } from "@react-oauth/google";

import NavbarMain from "./Components/Navbar/navbar";
import VikhilNavbar from "./Components/Navbar/vikhil_nabvbar";
import Footer from "./Components/Footer/footer";
import Career from "./Pages/Career/career";
import Team from "./Pages/Team/team";
import Casestudy2 from "./Pages/Casestudy/ufccasestudy";
import Services from "./Pages/Services/service";
import Airesponse from "./Pages/Ai_response/airesponse";
import Contactus from "./Pages/Contact/contactus";
import Home2 from "./Pages/home2/home2";
import UFCCasestudy from "./Pages/Casestudy/ufccasestudy";
import Featuredcasestudygreener from "./Pages/Casestudy/featuredcasestudygreener";
import Featuredcasestudygoma from "./Pages/Casestudy/featuredcasestudygoma";
import Featuredcasestudymeetha from "./Pages/Casestudy/featuredcasestudymeetha";
import Featuredcasestudyshm from "./Pages/Casestudy/featuredcasestudyshm";
import FeaturedcasestudyUFC from "./Pages/Casestudy/featuredcasestudyUFC";
import Featuredcasestudymgst from "./Pages/Casestudy/featuredcasestudymgst";
import Featuredcasestudymiam from "./Pages/Casestudy/featuredcasestudymiam";
import Featuredcasestudynino from "./Pages/Casestudy/featuredcasestudynino";
import Featuredcasestudyrb from "./Pages/Casestudy/featuredcasestudyR&B";
import Featuredcasestudybreadbar from "./Pages/Casestudy/featuredcasestudybreadbar";
import Featuredcasestudydoughtherapy from "./Pages/Casestudy/featuredcasestudydoughtherapy";
import Featuredcasestudymaiz from "./Pages/Casestudy/featuredcasestudymaiz";
import Featuredcasestudyburma from "./Pages/Casestudy/featuredcasestudyburma";
import Featuredcasestudyapsara from "./Pages/Casestudy/featuredcasestudyapsara";
import Featuredcasestudyvisionfood from "./Pages/Casestudy/featuredcasestudyvisionfood";
import Featuredcasestudymeemees from "./Pages/Casestudy/featuredcasestudymeemees";
import Featuredcasestudyburgerama from "./Pages/Casestudy/featuredcasestudyburgerama";
import PrivacyPolicy from "./Pages/Policies/PrivacyPolicy/privacypolicy";
import Policies from "./Pages/Policies/policies";
import TermsConditons from "./Pages/Policies/TermsConditions/termsconditions";
import TermsServices from "./Pages/Policies/TermsServices/termsservices";
import EndUserLicenceAgreement from "./Pages/Policies/EndUserLicenceAgreement/enduserlicenceagreement";
import FeaturedcasestudyUFC5 from "./Pages/Casestudy/featuredcasestudyUFC5";
import UFCCasestudy5 from "./Pages/Casestudy/ufccasestudy5";
import Greenr from "./Pages/Casestudy/greenr";
import Goma from "./Pages/Casestudy/goma";
import Shm from "./Pages/Casestudy/shm";
import Meetha from "./Pages/Casestudy/meetha";
import Mgst from './Pages/Casestudy/magst';
import FeedBackFormProd from "./Pages/FeedBackForm/ProdFeedBackForm/feedbackform";
import FeedBackFormTest from "./Pages/FeedBackForm/TestFeedBackForm/feedbackform";
import authReducer, { initialState } from "./reducers/authReducers";
import { useReducer } from "react";
import { LoginProvider, useLoginContext } from "./Context/login-context";
import ReactGA from "react-ga4";
import Blogs from "./Pages/blogs/index";
import ArticlePage from "./Pages/blogs/blog_content";
import Blogtwo from "./Pages/blogs/blog2";
import Blogthree from "./Pages/blogs/blog3";
import Blogfour from "./Pages/blogs/blog4";
import Blogfive from "./Pages/blogs/blog5";
import Blogsix from "./Pages/blogs/blog6";
import Blogseven from "./Pages/blogs/blog7";
import Blogeight from "./Pages/blogs/blog8";
import Blognine from "./Pages/blogs/blog9";
import Blogten from "./Pages/blogs/blog10";
import Blogeleven from "./Pages/blogs/blog11";
import Blogtweleve from "./Pages/blogs/blog12";
import Blogthirteen from "./Pages/blogs/blog13";
import Blogfourteen from "./Pages/blogs/blog14";
import Blogfifteen from "./Pages/blogs/blog15";
import Blogsixteen from "./Pages/blogs/blog16";
import Blogseventeen from "./Pages/blogs/blog17";
import Blogeighteen from "./Pages/blogs/blog18";
import Blognineteen from "./Pages/blogs/blog19";
import Blogtwenty from "./Pages/blogs/blog20";
import Blogtwentyone from "./Pages/blogs/blog21";
import Apsara from "./Pages/Casestudy/apsara";
import Breadbar from "./Pages/Casestudy/breadbar";
import Burgerama from "./Pages/Casestudy/burgerama";
import Burma from "./Pages/Casestudy/burma";
import Doughtherapy from "./Pages/Casestudy/doughtherapy";
import Maiz from "./Pages/Casestudy/maiz";
import Meemees from "./Pages/Casestudy/meemees";
import Miam from "./Pages/Casestudy/miam";
import Nino from "./Pages/Casestudy/nino";
import Rb from "./Pages/Casestudy/rb";
import Visionfood from "./Pages/Casestudy/visionfood";
import CafeSafarFeedBackForm from "./Pages/CafeSafarFeedbackForm";




function App() {
  ReactGA.initialize(process.env.NODE_ENV === 'development' ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID_DEV : process.env.REACT_APP_GOOGLE_ANALYTICS_ID_PROD);

  const [state, dispatch] = useReducer(authReducer, initialState);
  const { isLoggedIn, setIsLoggedIn, accessToken, setAccessToken } =
    useLoginContext();
  const location = useLocation();
  const isFeedbackForm = location.pathname.startsWith('/feedback-form') || location.pathname.startsWith('/cafe_safar/qrcode');



  console.log("app apps");
  return (
    <>
      <GoogleOAuthProvider clientId="696642202818-u4t5mqkt39mp9t9do521u6fa7k6lf12l.apps.googleusercontent.com">
        <AppProvider>

          {/* <VikhilNavbar /> */}
          {!isFeedbackForm && <VikhilNavbar />}
          <Routes>
            <Route path="/feedback-form/*" element={<FeedBackFormProd />} />
            <Route path="/feedback-form-testing-forms-endpoint/*" element={<FeedBackFormTest />} />
            <Route path="/cafe_safar/qrcode" element={<CafeSafarFeedBackForm/>} />
            <Route path="/" element={<Home2 />} />
            <Route path="/home" element={<Home2 />} />
            <Route path="/policies/*" element={<Policies />}>
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms-and-conditions" element={<TermsConditons />} />
              <Route path="terms-and-services" element={<TermsServices />} />
              <Route path="eula" element={<EndUserLicenceAgreement />} />
              <Route path="*" element={<PrivacyPolicy />} />
            </Route>
            <Route path="/about-us/join-us" element={<Career />} />
            <Route path="/about-us/our-team" element={<Team />} />
            <Route path="/services/know-more" element={<Services />} />
            <Route path="/services/ai-response" element={<Airesponse />} />
            <Route path="/contact-us" element={<Contactus />} />

            <Route
              path="/success-stories/United-farmers-creamery/"
              element={<FeaturedcasestudyUFC />}
            />

            <Route
              path="/success-stories/United-farmers-creamery/"
              element={<Casestudy2 />}
            />

            <Route
              path="/success-stories/Silver-beach-cafe/"
              element={<FeaturedcasestudyUFC5 />}
            />

            <Route
              path="/success-stories/Greenr-cafe/"
              element={<Featuredcasestudygreener />}
            />
            <Route
              path="/success-stories/Goma/"
              element={<Featuredcasestudygoma />}
            />
            <Route
              path="/success-stories/Meetha-by-radisson/"
              element={<Featuredcasestudymeetha />}
            />
            <Route
              path="/success-stories/SHM/"
              element={<Featuredcasestudyshm />}
            />
            <Route
              path="/success-stories/mag-st/"
              element={<Featuredcasestudymgst />}
            />
            <Route
              path="/success-stories/miam/"
              element={<Featuredcasestudymiam />}
            />
            <Route
              path="/success-stories/nino-burgers/"
              element={<Featuredcasestudynino />}
            />
            <Route
              path="/success-stories/ribbons-and-balloons/"
              element={<Featuredcasestudyrb />}
            />
            <Route
              path="/success-stories/bread-bar/"
              element={<Featuredcasestudybreadbar />}
            />
            <Route
              path="/success-stories/dough-therapy/"
              element={<Featuredcasestudydoughtherapy />}
            />
            <Route
              path="/success-stories/maiz-mexican/"
              element={<Featuredcasestudymaiz />}
            />
            <Route
              path="/success-stories/burma-burma/"
              element={<Featuredcasestudyburma />}
            />
            <Route
              path="/success-stories/apsara-ice-creams/"
              element={<Featuredcasestudyapsara />}
            />
            <Route
              path="/success-stories/vision-foods/"
              element={<Featuredcasestudyvisionfood />}
            />
            <Route
              path="/success-stories/meemees/"
              element={<Featuredcasestudymeemees />}
            />
            <Route
              path="/success-stories/burgerama/"
              element={<Featuredcasestudyburgerama />}
            />
            <Route
              path="/blogs"
              element={<Blogs />}
            />
            <Route
              path="/blogs/top-7-restaurant-review-sites-every-owner-must-monitor-for-success"
              element={<ArticlePage />}

            />
            <Route
              path="/blogs/from-feedback-to-action-tips-for-tracking-restaurant-reviews"
              element={<Blogtwo />}

            />
            <Route
              path="/blogs/unlocking-the-potential-of-automation-in-restaurant-review-management"
              element={<Blogthree />}

            />
            <Route
              path="/blogs/the-impact-of-online-reviews-on-restaurant-revenue"
              element={<Blogfour />}

            />
            <Route
              path="/blogs/turning-negative-reviews-into-positive-change-a-restaurants-guide-to-reputation-management"
              element={<Blogfive />}

            />
            <Route
              path="/blogs/maximize-your-success-the-ultimate-guide-to-mastering-customer-review-management"
              element={<Blogsix />}

            />
            <Route
              path="/blogs/ignite-your-restaurants-success-strategies-for-rave-reviews-and-repeat-business"
              element={<Blogseven />}

            />
            <Route
              path="/blogs/how-to-respond-to-restaurant-reviews-to-build-trust-and-loyalty"
              element={<Blogeight />}

            />
            <Route
              path="/blogs/the-power-of-reviews-how-ratings-can-elevate-your-restaurants-success"
              element={<Blognine />}

            />
            <Route
              path="/blogs/the-power-of-personalized-responses-engaging-with-restaurant-reviewers-effectively"
              element={<Blogten />}

            />
            <Route
              path="/blogs/revamp-your-review-strategy-avoid-common-pitfalls-and-boost-your-restaurants-reputation"
              element={<Blogeleven />}

            />
            <Route
              path="/blogs/the-power-of-datadriven-insights-in-analyzing-customer-reviews"
              element={<Blogtweleve />}

            />
            <Route
              path="/blogs/one-screen-to-rule-them-all-centralized-aggregator-management"
              element={<Blogthirteen />}

            />
            <Route
              path="/blogs/simplify-to-succeed-how-streamlined-menus-lead-to-higher-margins"
              element={<Blogfourteen />}

            />
            <Route
              path="/blogs/forecasting-success-ai-tools-that-predict-restaurant-trends"
              element={<Blogfifteen />}

            />
            <Route
              path="/blogs/seasonal-menus-how-limitedtime-offers-can-maximize-profits"
              element={<Blogsixteen />}

            />
            <Route
              path="/blogs/revamp-and-thrive-7-essential-tips-for-menu-optimization"
              element={<Blogseventeen />}

            />
            <Route
              path="/blogs/6-tactics-every-restaurant-should-implement-today-to-boost-sales"
              element={<Blogeighteen />}

            />
            <Route
              path="/blogs/mastering-the-art-of-strategic-discounts-transforming-customers-into-loyal-patrons-while-maximizing-profitability"
              element={<Blognineteen />}

            />
            <Route
              path="/blogs/why-deliveries-wont-hurt-your-dinein-breaking-the-myth-of-cannibalization"
              element={<Blogtwenty />}

            />
            <Route
              path="/blogs/beyond-the-plate-how-packaging-speaks-to-your-customers-before-they-even-take-a-bite"
              element={<Blogtwentyone />}

            />

            {isLoggedIn && (
              <>

                <Route path="/success-stories/United-farmers-creamery/full-story" element={<UFCCasestudy />} />

                <Route path="/success-stories/Silver-beach-cafe/full-story" element={<UFCCasestudy5 />} />
                <Route
                  path="/success-stories/Greenr-cafe/full-story"
                  element={<Greenr />}
                />
                <Route
                  path="/success-stories/Goma/full-story"
                  element={<Goma />}
                />
                <Route
                  path="/success-stories/Shm/full-story"
                  element={<Shm />}
                />
                <Route
                  path="/success-stories/Meetha-by-radisson/full-story"
                  element={<Meetha />}
                />
                <Route
                  path="/success-stories/mag-st/full-story"
                  element={<Mgst />}
                />
                <Route
                  path="/success-stories/apsara-ice-creams/full-story"
                  element={<Apsara />}
                />
                <Route
                  path="/success-stories/bread-bar/full-story"
                  element={<Breadbar />}
                />
                <Route
                  path="/success-stories/burgerama/full-story"
                  element={<Burgerama />}
                />
                <Route
                  path="/success-stories/burma-burma/full-story"
                  element={<Burma />}
                />
                <Route
                  path="/success-stories/doughtherapy/full-story"
                  element={<Doughtherapy />}
                />
                <Route
                  path="/success-stories/maiz-mexican/full-story"
                  element={<Maiz />}
                />
                <Route
                  path="/success-stories/meemees/full-story"
                  element={<Meemees />}
                />
                <Route
                  path="/success-stories/miam/full-story"
                  element={<Miam />}
                />
                <Route
                  path="/success-stories/nino-burgers/full-story"
                  element={<Nino />}
                />
                <Route
                  path="/success-stories/ribbons-and-balloons/full-story"
                  element={<Rb />}
                />
                <Route
                  path="/success-stories/visionfood/full-story"
                  element={<Visionfood />}
                />


              </>
            )}

            <Route path="*" element={<Home2 />} />
          </Routes>
          {!isFeedbackForm && <Footer />}
        </AppProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
