import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import meemeehome from "../../images/CaseStudy/meemee/meemeeshome.png";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import meemeesowner from "../../images/CaseStudy/meemee/meemeesfounder.png";
import meemeeone from '../../images/CaseStudy/meemee/meemees 1.png';
import meemeetwo from '../../images/CaseStudy/meemee/Meemes 2.png';
import meemeethree from '../../images/CaseStudy/meemee/meemeesthree.png';


const Meemees = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
    }, []);


    const infoAboutCompany = {
        Brand: `Meemee's`,
        Platform: "Zomato & Swiggy",
        Cuisine: "Ice Cream",
        Location: "Mumbai",
        AOV: "High",
        "Target Audience": "Premium",
    };

    const challenge = {
        text1: "Quality control, operational inefficiencies, inconsistent service",
        text2: `Meemee, a growing food brand, operates through multiple kitchens that are neither owned nor managed by them. This unique operating model posed significant challenges in maintaining consistent quality and operational efficiency across all locations. Without direct control over kitchen operations, it became critical to establish a robust system to monitor hygiene, inventory management, and other operational metrics to ensure smooth functioning and consistent service delivery.`,
    };

    const impact = {
        impactCards: {
            card1: {
                header: "Stock-Out",
                figures: 0,
                figurePrefix: "",
                figuresPostfix: "%",
                content: "Enhanced inventory management resulted in better throughput per store.",
            },
            card2: {
                header: "Engagement",
                figures: 0,
                figuresPostfix: "%",
                content: "Stock-outs were reduced, improving the consistency of their menu offerings",
            },
            card3: {
                header: "Efficiency",
                figures: 0,
                figuresPostfix: "%",
                content: "Saved time for the brand",
            },
        },
        owner_views: {
            title: "Meemees",
            description: `We have several dark stores that have 3rd party operators and hence for us to track the customer apps across was a challenge. After partnering with restaverse, operations have become smoother- their menu audits feature has been the biggest lifter for us. It has helped us track variant and sub-variant wise gaps and how we can solve for those things better across all online platforms.`,
            name: "Meha Agarwal",
            designation: "Founder, Meemees",
            owner_img: meemeesowner
        },
    };


    const solutions = {
        1: {
            header: "Hygiene Metric Implementation",
            content: `A detailed audit system was introduced to track hygiene and operational metrics across all partner kitchens. This included monitoring cleanliness, food safety, and adherence to standard operating procedures.`,
            src: meemeeone,
        },
        2: {
            header: "Inventory Management Optimization",
            content:
                "The audits highlighted inefficiencies in inventory handling. By addressing these gaps, the brand streamlined inventory processes to reduce wastage and improve stock utilization.",
            src: meemeetwo,
        },
        3: {
            header: "Operational Alignment",
            content:
                "Training and standardization efforts were introduced for partner kitchens to align them with Meemee’s operational standards, ensuring consistency in service and quality.",
            src: meemeethree,
        }

    };

    return (
        <>
            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div
                    // className="casestudy "

                    className="container-lg"
                >
                    <div
                        className="casestudy"
                        style={{ fontFamily: "Poppins , sans-serif" }}
                    >
                        <div className="main-section">
                            {/* left-main-section */}
                            <div className="left-main-section">
                                <div className="left-section-desc">
                                    Meemees : Centralised out of stock checker
                                </div>
                                <div className="info-tags">
                                    {Object.keys(infoAboutCompany).map((key) => (
                                        <Infotags
                                            key={key}
                                            header={key}
                                            value={infoAboutCompany[key]}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* image */}
                            <img src={meemeehome} className="image-main-section"></img>
                        </div>


                        <div className="sections">
                            <nav className="section-navbar">
                                <a href="#challenge">Challenge</a>
                                <a href="#solution">Solution</a>
                                <a href="#impact">Impact</a>
                            </nav>

                            <section id="challenge">
                                <ChallengeSection
                                    text1={challenge.text1}
                                    text2={challenge.text2}
                                />
                                {/* <Challenge/> */}
                            </section>

                            <section id="solution">
                                <SolutionSection solutions={solutions} />
                            </section>

                            <section id="impact">
                                {/* <Impact /> */}
                                <ImpactSection impact={impact} />
                            </section>

                            <div className="cardcase" ref={ref}>
                                {inView && (
                                    <div className="cardcasestudyHeader animate-from-top">
                                        Success Story
                                    </div>
                                )}

                                <CaseStudyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <SuceessStory
                    titletext="Be ahead of the curve"
                    desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
                    buttontext="Join Waitlist"
                    redirect="/contact-us"
                />
            </div>
        </>
    );
};

export default Meemees;
