import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Rating,
  CircularProgress,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
// import CustomEmojiPicker from "./customemojipicker";
import Modal from "@mui/material/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { green } from "@mui/material/colors";
import cafeSafarLogo from "../../images/restaurants_logos/cafesafar.webp"


const CafeSafarFeedBackForm = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [brandUrl, setBrandUrl] = useState("");
  const [formExist, setFormExist] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lockForm, setlockForm] = useState(false);
  const brand = decodeURIComponent(searchParams.get("brand"));
  const [open, setOpen] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    // bgcolor: "background.paper",
    backgroundColor: "#F6F6F6",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };


  const [formData, setFormData] = useState({
    name: "",
    phone_number: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // console.log(decodeURIComponent(searchParams.get("brand")));
  const handleRatingChange = (event, newValue) => {
    // console.log(newValue);
    setFormData({
      ...formData,
      rating: newValue,
    });
  };


  const handleSubmit = async (e) => {
    
    e.preventDefault();
    if ((!/^\d{0,10}$/.test(formData.phone_number))) {
      // Handle the case where rating is not provided
      alert("Only numbers are allowed in phone number field");
      return;
    }

    try {
     
      setlockForm(true);

      const url =
        "https://prod.restaverse.com/api/cafe_safar_linktree";
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        // throw new Error(`HTTP error! status: ${response.status}`);
        setIsFormSubmitted(false);
      } else {
        setFormData({
          name: "",
          phone_number: ""
        });
        setIsFormSubmitted(true);
        window.location.href = "https://linktr.ee/Cafesafar";
      }
    } catch {
      setIsFormSubmitted(false);
    } finally {
      setOpen(true);
      setlockForm(false)
    }

    // }

    // Handle form submission logic here
  };


  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#F6F6F6",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    ); // Display loading screen

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Ensure vertical stacking
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#F6F6F6",
      }}
    >
      {formExist ? (
        <>
          <img
            src={cafeSafarLogo}
            alt="Brand Logo"
            width={"110px"}
            height={"80px"}
            style={{ marginBottom: "10px", marginTop: "10px" }}
          />
          <Card
            sx={{
              maxWidth: 600,
              width: "100%",
              padding: 3,
              borderRadius: 2,
              boxShadow: "10px 10px #FFB22A", // Custom shadow with gold color
              pointerEvents: lockForm ? "none" : "auto",
              opacity: lockForm ? 0.5 : 1,
            }}
          >
            <CardContent>
              <Typography variant="h4" gutterBottom>
                ✨ We'd love to hear from you
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                Hey there! You’re here because you’re an awesome customer! 👏
                Stay tuned for future updates and exclusive offers—we’d love to
                keep you in the loop! 🚀
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2, // Adds spacing between form elements
                  alignItems: "stretch", // Ensures the form takes up the full width
                }}
              >
                <TextField
                  label="Your Name"
                  name="name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  placeholder="Enter your name"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      label="Your Phone Number"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={(e) => {
                        const newValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                        if (newValue.length <= 10) {
                          handleChange(e);
                        }
                      }}
                      fullWidth
                      margin="normal"
                      placeholder="Enter your phone number"
                      inputProps={{
                        maxLength: 10, // Restrict input to 10 digits
                      }}
                      error={!/^\d{0,10}$/.test(formData.phone_number)} // Check if input contains only digits
                      helperText={!/^\d{0,10}$/.test(formData.phone_number) ? 'Only numbers are allowed' : ''}
                    />
                  </Grid>

                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 2,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#FFB229",
                      textTransform: 'None'
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...style, padding: 2 }}>
              {isFormSubmitted ? (
                <>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CheckCircleOutlineIcon
                      sx={{
                        color: green[500],
                        fontSize: 60,
                        marginInline: "auto",
                      }}
                    />
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    Thank You!
                  </Typography>
                </>
              ) : (
                <>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CancelIcon
                      sx={{
                        color: green[500],
                        fontSize: 60,
                        marginInline: "auto",
                      }}
                    />
                  </Box>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                  >
                    Feedback not submitted.
                  </Typography>
                </>
              )}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  sx={{ mt: 2, backgroundColor: "#FFB229" }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <Typography variant="h6" color="textSecondary">
          Page Not Found
        </Typography>
      )}
    </Box>
  );
};

export default CafeSafarFeedBackForm;
