import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import ninohome from "../../images/CaseStudy/nino/ninoburgerhome.png";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import ninoowner from "../../images/CaseStudy/nino/ninofounder.png";
import ninoone from '../../images/CaseStudy/nino/Nino 1.png';
import ninotwo from '../../images/CaseStudy/nino/NIno 2.png';
import ninothree from '../../images/CaseStudy/nino/ninothree.png';
import ninofour from '../../images/CaseStudy/nino/ninofour.png';


const Nino = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
    }, []);


    const infoAboutCompany = {
        Brand: `Nino`,
        Platform: "Zomato & Swiggy",
        Cuisine: "Burgers",
        Location: "Mumbai",
        AOV: "High",
        "Target Audience": "Mass Premium",
    };

    const challenge = {
        text1: "Uninspired menu, low ratings, missed opportunities",
        text2: `Nino Burgers faced significant hurdles in maintaining and growing their market presence. Their meat-based burgers, a core part of their menu, were struggling to resonate with customers, leading to underwhelming sales. Additionally, their product lineup lacked differentiation and innovation, which limited their ability to attract new customers or build excitement around their brand. They also missed out on capitalizing on late-night meal times, a high-intent period with strong customer demand, leaving a substantial revenue opportunity untapped.Food ratings were low, give eg. of avo beet feta`,
    };

    const impact = {
        impactCards: {
            card1: {
                header: "Menu Opens",
                figures: 30,
                figurePrefix: "",
                figuresPostfix: "%",
                content: "Increase in menu opens",
            },
            card2: {
                header: "Conversion",
                figures: 20,
                figuresPostfix: "%",
                content: "Increase in menu-to-order conversions",
            },
            card3: {
                header: "Stock Out",
                figures: 50,
                figuresPostfix: "%",
                content: "Increase in reduction in stock out",
            },
        },
        owner_views: {
            title: "Nino Burgers",
            description: `Sid & Team have been with us on all pivotal moments and we've been on restaverse since day 0. They've the ability to show you the right picture and the mirror that founders often need, especially when they're scaling. They're always available when you need them. Their tool is a part of our daily checklist across business functions and it has helped cut down a lot of human hours to make things optimum across our business functions.`,
            name: "Nishant Jhaveri",
            designation: "Co-Founder, Eat Nino",
            owner_img: ninoowner
        },
    };


    const solutions = {
        1: {
            header: "Product Optimization",
            content: `We conducted an in-depth analysis of customer reviews and identified lower-rated products that needed improvement. Tailored recommendations were made to enhance flavors, presentation, and overall quality, ensuring these products better met customer expectations.`,
            src: ninoone,
        },
        2: {
            header: "Driving Innovation",
            content: "Recognizing the growing trend toward vegetarian diets, we encouraged Nino Burgers to innovate their menu with new vegetarian options. These included unique and creative burgers designed to appeal to both vegetarian and non-vegetarian customers, ensuring broad appeal while addressing a market gap.",
            src: ninotwo,
        },
        3: {
            header: "Repositioning for Category Leadership",
            content: "Given the underperformance of meat-based burgers, the strategy shifted to focus on elevating vegetarian burgers as a signature category. With consistent quality improvements and effective promotion, Nino Burgers carved out a niche as a leader in the vegetarian burger space.",
            src: ninothree,
        },
        4: {
            header: "Activating Late-Night Sales",
            content: "By extending operating hours and targeting late-night diners, Nino Burgers tapped into a new audience. This move captured high-intent customers looking for convenient, delicious meals during unconventional hours, creating an additional revenue stream.",
            src: ninofour,
        }

    };

    return (
        <>
            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div
                    // className="casestudy "

                    className="container-lg"
                >
                    <div
                        className="casestudy"
                        style={{ fontFamily: "Poppins , sans-serif" }}
                    >
                        <div className="main-section">
                            {/* left-main-section */}
                            <div className="left-main-section">
                                <div className="left-section-desc">
                                    Nino Burgers : Revenue Growth via dishwise feedback analysis
                                </div>
                                <div className="info-tags">
                                    {Object.keys(infoAboutCompany).map((key) => (
                                        <Infotags
                                            key={key}
                                            header={key}
                                            value={infoAboutCompany[key]}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* image */}
                            <img src={ninohome} className="image-main-section"></img>
                        </div>


                        <div className="sections">
                            <nav className="section-navbar">
                                <a href="#challenge">Challenge</a>
                                <a href="#solution">Solution</a>
                                <a href="#impact">Impact</a>
                            </nav>

                            <section id="challenge">
                                <ChallengeSection
                                    text1={challenge.text1}
                                    text2={challenge.text2}
                                />
                                {/* <Challenge/> */}
                            </section>

                            <section id="solution">
                                <SolutionSection solutions={solutions} />
                            </section>

                            <section id="impact">
                                {/* <Impact /> */}
                                <ImpactSection impact={impact} />
                            </section>

                            <div className="cardcase" ref={ref}>
                                {inView && (
                                    <div className="cardcasestudyHeader animate-from-top">
                                        Success Story
                                    </div>
                                )}

                                <CaseStudyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <SuceessStory
                    titletext="Be ahead of the curve"
                    desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
                    buttontext="Join Waitlist"
                    redirect="/contact-us"
                />
            </div>
        </>
    );
};

export default Nino;
