import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import apsarahome from "../../images/CaseStudy/apsara/apsarahome.png";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import apsaraowner from "../../images/CaseStudy/apsara/apsarafounder.png"
import apsaraone from '../../images/CaseStudy/apsara/Apsara 1.png';
import apsaratwo from '../../images/CaseStudy/apsara/apsaratwo.png';
import apsarathree from '../../images/CaseStudy/apsara/apasarathree.png';


const Apsara = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
    }, []);


    const infoAboutCompany = {
        Brand: ` Apsara ice cream`,
        Platform: "Zomato & Swiggy",
        Cuisine: "Desserts",
        Location: "Across India",
        AOV: "Mid",
        "Target Audience": "Mass",
    };

    const challenge = {
        text1: "Inconsistent reviews, weak engagement, declining sales",
        text2: `Apsara Ice Cream, a brand known for its unique artisanal flavors, faced challenges in maintaining its stellar online reputation. While its products were widely appreciated, inconsistent responses to reviews and a lack of proactive engagement on social media began to impact its digital image. Customers expressed concerns over delays in addressing complaints, while the absence of a consistent brand voice across platforms diluted its messaging. These issues led to mixed customer perceptions, affecting footfall and sales.`,
    };

    const impact = {
        impactCards: {
            card1: {
                header: "Engagement",
                figures: 0,
                figurePrefix: "",
                figuresPostfix: "%",
                content: "Ratings on aggregator platforms saw a significant boost, attracting new customers and increasing visibility",
            },
            card2: {
                header: "Conversion",
                figures: 0,
                figuresPostfix: "%",
                content: "Increase repeat visits and encouraging word-of-mouth recommendations.",
            },
            card3: {
                header: "Engagement",
                figures: 0,
                figuresPostfix: "%",
                content: "Improving customer satisfaction and overall experience.",
            },
        },
        owner_views: {
            title: "Apsara Ice Creams",
            description: `We've been using the restaverse tool for response to customer's reviews and complaints across several digital platforms. Our team has saved decent amount of their time by onboarding restaverse in their daily life and it has now become very easy for us to track several franchises across the country and track new flavour launches product feedback around it as well. Knowing how quickly (or not) we’ve responded to customer reviews—like the 80% within 6 hours—is super helpful in managing our team’s accountability.`,
            name: "Keyur Shah",
            designation: "MD, Apsara Ice Creams",
            owner_img: apsaraowner
        },
    };


    const solutions = {
        1: {
            header: "Centralized Review Management",
            content: `We consolidated Apsara’s reviews from multiple platforms into a single, user-friendly dashboard. This system ensured that every review, whether glowing praise or constructive criticism, was acknowledged and addressed promptly.`,
            src: apsaraone,
        },
        2: {
            header: "Personalized and Timely Responses",
            content:
                "Our team worked closely with Apsara to craft thoughtful and personalized responses to customer reviews. Negative feedback became opportunities to resolve concerns, while positive reviews were celebrated, fostering deeper customer connections.",
            src: apsaratwo,
        },
        3: {
            header: "Actionable Insights from Feedback",
            content:
                "Through data-driven analysis of customer feedback, we identified recurring issues such as flavor availability and delivery inefficiencies. These insights allowed Apsara to implement targeted improvements, ensuring a seamless customer experience.",
            src: apsarathree,
        }

    };

    return (
        <>
            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div
                    // className="casestudy "

                    className="container-lg"
                >
                    <div
                        className="casestudy"
                        style={{ fontFamily: "Poppins , sans-serif" }}
                    >
                        <div className="main-section">
                            {/* left-main-section */}
                            <div className="left-main-section">
                                <div className="left-section-desc">
                                    Apsara Ice Creams : Centralised review management system
                                </div>
                                <div className="info-tags">
                                    {Object.keys(infoAboutCompany).map((key) => (
                                        <Infotags
                                            key={key}
                                            header={key}
                                            value={infoAboutCompany[key]}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* image */}
                            <img src={apsarahome} className="image-main-section"></img>
                        </div>


                        <div className="sections">
                            <nav className="section-navbar">
                                <a href="#challenge">Challenge</a>
                                <a href="#solution">Solution</a>
                                <a href="#impact">Impact</a>
                            </nav>

                            <section id="challenge">
                                <ChallengeSection
                                    text1={challenge.text1}
                                    text2={challenge.text2}
                                />
                                {/* <Challenge/> */}
                            </section>

                            <section id="solution">
                                <SolutionSection solutions={solutions} />
                            </section>

                            <section id="impact">
                                {/* <Impact /> */}
                                <ImpactSection impact={impact} />
                            </section>

                            <div className="cardcase" ref={ref}>
                                {inView && (
                                    <div className="cardcasestudyHeader animate-from-top">
                                        Success Story
                                    </div>
                                )}

                                <CaseStudyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <SuceessStory
                    titletext="Be ahead of the curve"
                    desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
                    buttontext="Join Waitlist"
                    redirect="/contact-us"
                />
            </div>
        </>
    );
};

export default Apsara;
