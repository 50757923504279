import React from "react";
import "./challenge.css";
import AnimatedNumber from "../Team/animatednumber";

const ImpactSection = ({ impact }) => {
  const impactCards = impact.impactCards;

  return (
    <div className="challenge">
      <div>
        <h3> Impact</h3>
        <hr></hr>
      </div>
      <div className="impact-cards">
        {Object.keys(impactCards).map((key) => {
          const card = impactCards[key];
          return (
            <div className="impact-single-card" key={key}>
              <div className="impact-card-header">{card.header}</div>
              <div className="impact-card-figure">
                {card.figures !== 0 && ( // Conditionally render AnimatedNumber
                  <AnimatedNumber
                    end={card.figures}
                    duration={3}
                    unit={card.figuresPostfix}
                  />
                )}
              </div>
              <div className="impact-card-content">{card.content}</div>
            </div>
          );
        })}
      </div>

      <div className="impact-section">
        <div className="impact-left-section">
          <h1>{impact.owner_views.title}</h1>
          <p>{impact.owner_views.description}</p>
          <div className="writer-name">
            <h3>{impact.owner_views.name}</h3>
            <span>{impact.owner_views.designation}</span>
          </div>
        </div>

        <img src={impact.owner_views.owner_img} className="impact-img" alt="Owner" />
      </div>
    </div>
  );
};

export default ImpactSection;
