import React, { useState } from 'react';
import './index.css';
import { Typography, Box, Grid, Divider, TextField, Button, Card, CardActions, CardContent, CardMedia, Modal } from '@mui/material';
import { keyframes } from '@mui/system';
import FeaturedArticle from './featuredarticle';
import LatestArticles from './latestarticles';
import ArticleSection from './articlesection';
import ArticlePage from './blog_content';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import one from '../../images/blogs/1.png'
import twentyone from '../../images/blogs/21.png';
import twentythree from '../../images/blogs/23.png';
import twentyfive from '../../images/blogs/25.png';
import twentyseven from '../../images/blogs/27.png';
import twentynine from '../../images/blogs/29.png';
import thirtyone from '../../images/blogs/31.png';
import thirtythree from '../../images/blogs/33.png';
import thirtyfive from '../../images/blogs/35.png';
import thirtyseven from '../../images/blogs/37.png';
import thirtynine from '../../images/blogs/39.png';
import forty from '../../images/blogs/40.png';

import { blog_email } from '../../APIs/apifunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0); 
  }
`;
const articles = [
    {
        title: 'The Power of Personalized Responses: Engaging with Restaurant...',
        author: 'Akshay Gour',
        date: '03/07/2024',
        imageUrl: twentyone,
        link: '/blogs/the-power-of-personalized-responses-engaging-with-restaurant-reviewers-effectively'
    },
    {
        title: 'The Power of Data-Driven Insights in Analyzing Customer Reviews',
        author: 'Urmi Dangi',
        date: '06/10/2024',
        imageUrl: twentythree,
        link: '/blogs/the-power-of-datadriven-insights-in-analyzing-customer-reviews'
    },
    {
        title: 'One Screen to Rule Them All: Centralized Aggregator Management',
        author: 'Shiksha Chauhan',
        date: '10/09/2024',
        imageUrl: twentyfive,
        link: '/blogs/one-screen-to-rule-them-all-centralized-aggregator-management'
    },
    {
        title: 'Simplify to Succeed: How Streamlined Menus Lead to Higher Margins',
        author: 'Charmi Jain',
        date: '08/05/2024',
        imageUrl: twentyseven,
        link: '/blogs/simplify-to-succeed-how-streamlined-menus-lead-to-higher-margins'
    },
    {
        title: "Why Deliveries Won't Hurt Your Dine-In: Breaking the Myth of Cannibalization",
        author: 'Valeron Toscano',
        date: '13/07/2024',
        imageUrl: thirtynine,
        link: '/blogs/why-deliveries-wont-hurt-your-dinein-breaking-the-myth-of-cannibalization'
    },
];
const articlestwo = [
    {
        title: 'Forecasting Success: AI Tools that Predict Restaurant Trends',
        author: 'Manish Swami',
        date: '03/09/2024',
        imageUrl: twentynine,
        link: '/blogs/forecasting-success-ai-tools-that-predict-restaurant-trends'
    },
    {
        title: 'Seasonal Menus: How Limited-Time Offers Can Maximize Profits',
        author: 'Arsh Khanna',
        date: '10/10/2024',
        imageUrl: thirtyone,
        link: '/blogs/seasonal-menus-how-limitedtime-offers-can-maximize-profits'
    },
    {
        title: 'Revamp and Thrive: 7 Essential Tips for Menu Optimization',
        author: 'Rishon Dharia',
        date: '30/06/2024',
        imageUrl: thirtythree,
        link: '/blogs/revamp-and-thrive-7-essential-tips-for-menu-optimization'
    },
    {
        title: '6 Tactics Every Restaurant Should Implement Today to Boost Sales',
        author: 'Amar Savla',
        date: '22/06/2024',
        imageUrl: thirtyfive,
        link: '/blogs/6-tactics-every-restaurant-should-implement-today-to-boost-sales'
    },
    {
        title: 'Mastering the Art of Strategic Discounts: Transforming Customers into Loyal...',
        author: 'Yash Bokadia',
        date: '16/06/2024',
        imageUrl: thirtyseven,
        link: '/blogs/mastering-the-art-of-strategic-discounts-transforming-customers-into-loyal-patrons-while-maximizing-profitability'
    },
    {
        title: 'Beyond the Plate: How Packaging Speaks to Your Customers...',
        author: 'Pratik Agnihotri',
        date: '08/10/2024',
        imageUrl: forty,
        link: '/blogs/beyond-the-plate-how-packaging-speaks-to-your-customers-before-they-even-take-a-bite'
    },
];

function Index() {
    const [showPrivacyText, setShowPrivacyText] = useState(false);
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubscribe = () => {
        if (email) {
            const data = { email };
            blog_email(data)
                .then((response) => {
                    console.log('Email subscribed successfully:', response);
                    setEmail(''); 
                    setOpen(true); 
                    toast.success('Subscribed successfully!', {
                        position: 'top-right'
                    });
                })
                .catch((err) => {
                    console.error('Error subscribing email:', err);
                    toast.error('Failed to subscribe.', {
                        position: 'top-right'
                    }); 
                });
        } else {
            toast.warn('Email is required', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };
    const handleClose = () => {
        setOpen(false); 
    };


    const handleFocus = () => {
        setShowPrivacyText(true);
    };

    return (
        <>

            <ToastContainer />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >

                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: { xs: '36px', sm: '67px' },
                                color: "#213343"
                            }}
                        >
                            Empowering
                        </Typography>

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h5"
                            component="p"
                            sx={{
                                marginLeft: { sm: 1 },
                                marginTop: { xs: 1, sm: 2 },
                                fontFamily: 'Lexend Deca, sans-serif',
                                color: "#213343",
                                fontSize: { xs: '16px', sm: '22px' }
                            }}
                        >
                            Restaurants with Insights, Trends, and Strategies for the Future.
                        </Typography>
                    </Box>


                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: { xs: 'column', sm: 'row' },
                            marginTop: 0,
                        }}
                    >
                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h6"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                paddingRight: '40px',
                                color: "#213343",
                                fontWeight: 300,
                                fontSize: { xs: '16px' }
                            }}
                        >
                            Subscribe and stay up to date with the latest marketing tips and news.
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            label="Your email address"
                            variant="outlined"
                            sx={{
                                width: { xs: '100%', sm: '35%' },
                                marginBottom: { xs: 2, sm: 0 },
                                backgroundColor: '#f6f9fc',
                                marginRight: { sm: 2 },
                                '& .MuiOutlinedInput-root': {
                                    height: '50px',
                                    padding: '0',
                                },
                                '& .MuiInputLabel-root': {
                                    fontSize: '16px',
                                    color: 'rgb(103,103,103)',
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontWeight: 300
                                },
                                '& input': {
                                    padding: '10px',
                                    fontSize: '14px',
                                }
                            }}
                            onFocus={handleFocus}
                            value={email}
                            onChange={handleEmailChange}
                        />

                        <Button
                            variant="contained"
                            sx={{
                                background: 'rgb(108, 65, 255)',
                                height: '50px',
                                textTransform: 'none',
                                width: { xs: '100%', sm: 'auto' },
                                fontFamily: 'Lexend Deca, sans-serif',
                                paddingRight: '16px',
                                borderRadius: 2
                            }}
                            onClick={handleSubscribe}
                        >
                            Subscribe
                        </Button>

                    </Box>

                    {showPrivacyText && (
                        <Typography
                            variant="body2"
                            color="black"
                            sx={{
                                marginTop: 2,
                                animation: `${fadeInUp} 2s ease-in-out`,
                                opacity: 1,
                                color: "#213343",
                                fontWeight: 200,
                                fontSize: { sm: '14px' },
                                fontFamily: 'Lexend Deca, sans-serif'
                            }}
                        >
                            We’re committed to your privacy. Restaverse uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our <a href='https://restaverse.com/policies/privacy-policy' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '1px', color: '#0068b1', fontWeight: 400 }}>privacy policy.</a>
                            <br></br><br></br>
                            This form is protected by reCAPTCHA and the Google <a href='https://restaverse.com/policies/privacy-policy' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '1px', color: '#0068b1', fontWeight: 400 }}>Privacy Policy</a> and <a href='https://restaverse.com/policies/terms-and-services' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '1px', color: '#0068b1', fontWeight: 400 }}>Terms of Service</a> apply.

                        </Typography>

                    )}

                    <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: 'rgb(108, 65, 255)', marginTop: "20px" }} />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: 'space-between',
                            alignItems: 'stretch',
                            marginTop: 4,
                            gap: 4,

                        }}
                    >
                        <Link
                            to={{
                                pathname: "/blogs/top-7-restaurant-review-sites-every-owner-must-monitor-for-success",
                                state: {
                                    title: "The Power of Partnerships and Innovation, according to New Balance's Calvin Goncalves",
                                    writerName: "Erica Santiago",
                                    // writerImage: writerImage,
                                    publishDate: "September 17, 2024",
                                },
                            }}
                        >
                            <Card sx={{
                                maxWidth: 600,
                                flex: 1.5,
                                backgroundColor: '#FEF5EB',
                                boxShadow: 'none',
                                border: 1,
                                borderColor: '#FEF5EB',
                                '&:hover': {
                                    '& .article-title, & .date-wrapper': {
                                        textDecoration: 'underline',
                                        textDecorationColor: 'black',
                                    },
                                },
                            }}>
                                <CardMedia
                                    sx={{
                                        height: 280,
                                        width: 700,
                                        backgroundColor: '#FCEBD7'
                                    }}
                                    image={one}
                                    title="online reviews"

                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div"
                                        sx={{
                                            fontFamily: 'Lexend Deca, sans-serif',
                                            fontWeight: 500,
                                            marginTop: 2,
                                            color: "#213343",

                                        }}
                                        className="article-title"
                                    >

                                        Top 7 Restaurant Review Sites Every Owner Must Monitor for Success
                                    </Typography>
                                    <Typography variant="body2"
                                        sx={{
                                            color: 'text.secondary',
                                            fontFamily: 'Lexend Deca, sans-serif',
                                            color: "#213343",
                                            fontWeight: 300
                                        }}>
                                        Creating a marketing plan? Learn how, plus get started with these free templates as a guide.
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4, marginTop: '30px' }}>
                                        <Typography sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: "16px", fontWeight: 300, color: '#5D8BBE' }}>Shiksha Chauhan</Typography>
                                        <Typography sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: "16px", fontWeight: 300, color: '#5D8BBE' }}>12/06/2024 </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Link>
                        <FeaturedArticle />
                    </Box>

                    <LatestArticles />

                    <div>
                        <ArticleSection title="Technology" articles={articles} />
                    </div>

                    <div style={{ marginTop: "50px" }}>
                        <ArticleSection title="Industry Trends" articles={articlestwo} />
                    </div>
                </Box>
            </Box>
        </>
    );
}

export default Index;
