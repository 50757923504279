import React from "react";
import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import AnimatedNumber from "../../Components/Team/animatednumber";
import { StyledButton } from "../home2/home2";
import { Link } from "react-router-dom";
import "./casestudy.css";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import {
    validateAccessToken,
    fetchUserInfo,
    handleUserInfo,
    handleLogout,
    handleLogin,
    fetch_Api,
    delay,
} from "../../Utils/auth";
import { useGoogleLogin } from "@react-oauth/google";
import { useLoginContext } from "../../Context/login-context";
import { useNavigate } from "react-router-dom";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import apsara from '../../images/CaseStudy/apsara/apsarahome.png';


const Featuredcasestudyapsara = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const theme = useTheme();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { isLoggedIn, setIsLoggedIn, accessToken, setAccessToken } =
        useLoginContext();

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
        handleUserInfo(setAccessToken, setIsLoggedIn, validateAccessToken);
    }, []);

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            console.log("Google login successful", tokenResponse);

            await handleLogin(
                tokenResponse,
                setIsLoggedIn,
                setAccessToken,
                fetchUserInfo,
                validateAccessToken,
                fetch_Api
            );

            console.log("Processing login...");

            const userInfo = localStorage.getItem("userInfo");
            console.log(
                "Retrieved userInfo from localStorage before parsing:",
                userInfo
            );

            if (userInfo) {
                const parsedUserInfo = JSON.parse(userInfo);
                console.log("Parsed userInfo from localStorage:", parsedUserInfo);
                const email = parsedUserInfo.email;
                console.log("User email:", email);

                try {
                    const response = await fetch(
                        "https://prod.restaverse.com/api/login_increment",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                queryType: "casestudy",
                                email: email,
                            }),
                        }
                    );

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const data = await response.json();
                    console.log("Login increment response:", data);
                } catch (error) {
                    console.error("Error incrementing login count:", error);
                }
            } else {
                console.error("User info not found in localStorage");
            }

            await delay(500);
            navigate("/success-stories/apsara-ice-creams/full-story");
        },
        onError: () => {
            console.log("Login Failed");
        },
        redirect_uri: "postmessage",
    });

    return (
        <>
            <div className="casestudy-main">
                <div className="container-lg" style={{ backgroundColor: "#1d0f33" }}>
                    <div className="casestudy ">
                        <div className="main-section" style={{ marginBottom: "95px" }}>
                            <div className="left-main-section">
                                <div
                                    className="left-section-desc"
                                    style={{
                                        color: "white",
                                        fontFamily: "'Poppins', sans-serif",
                                    }}
                                >
                                    Apsara Ice Creams : Centralised review management system
                                </div>
                                <div className="info-tags">
                                    <div>
                                        <Grid
                                            container
                                            spacing={2}
                                            style={{ marginBottom: "40px" }}
                                        >
                                            <Grid item xs={12} sm={12} style={{ paddingTop: "0px" }}>
                                                {isLoggedIn ? (
                                                    <StyledButton
                                                        component={Link}
                                                        to="/success-stories/apsara-ice-creams/full-story"
                                                        style={{ color: "white" }}
                                                    >
                                                        Read Full Story
                                                    </StyledButton>
                                                ) : (
                                                    <StyledButton
                                                        component={Link}
                                                        onClick={login}
                                                        style={{ color: "white" }}
                                                    >
                                                        Read Full Story
                                                    </StyledButton>
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                style={{
                                                    ...(isSmallScreen && { textAlign: "center" }),
                                                }}
                                            >
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={faLayerGroup}
                                                        aria-hidden="true"
                                                        style={{
                                                            fontSize: "2rem", // Adjust the size as needed
                                                            color: "white",
                                                            width: "30px", // Adjust the size as needed
                                                            height: "30px",
                                                            padding: "10px",
                                                            borderRadius: "50%",
                                                            backgroundColor: "#6c41ff",
                                                        }}
                                                    />
                                                </div>
                                                <br></br>

                                                <Typography
                                                    variant="body1"
                                                    color="white"
                                                    sx={{
                                                        fontSize: "0.8rem",
                                                        fontFamily: "'Poppins', sans-serif",
                                                    }}
                                                >
                                                    Ratings on aggregator platforms saw a significant boost, attracting new customers and increasing visibility
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                style={{
                                                    ...(isSmallScreen && { textAlign: "center" }),
                                                }}
                                            >
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={faLayerGroup}
                                                        aria-hidden="true"
                                                        style={{
                                                            fontSize: "2rem", // Adjust the size as needed
                                                            color: "white",
                                                            width: "30px", // Adjust the size as needed
                                                            height: "30px",
                                                            padding: "10px",
                                                            borderRadius: "50%",
                                                            backgroundColor: "#6c41ff",
                                                        }}
                                                    />
                                                </div>
                                                <br></br>
                                                
                                                <Typography
                                                    variant="body1"
                                                    color="white"
                                                    style={{
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontSize: "0.8rem",
                                                    }}
                                                >
                                                   Increase repeat visits and encouraging word-of-mouth recommendations.
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                style={{
                                                    ...(isSmallScreen && { textAlign: "center" }),
                                                }}
                                            >
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={faLayerGroup}
                                                        aria-hidden="true"
                                                        style={{
                                                            fontSize: "2rem", // Adjust the size as needed
                                                            color: "white",
                                                            width: "30px", // Adjust the size as needed
                                                            height: "30px",
                                                            padding: "10px",
                                                            borderRadius: "50%",
                                                            backgroundColor: "#6c41ff",
                                                        }}
                                                    />
                                                </div>
                                                <br></br>
                                                
                                                <Typography
                                                    variant="body1"
                                                    color="white"
                                                    style={{
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontSize: "0.8rem",
                                                    }}
                                                >
                                                  Improving customer satisfaction and overall experience.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>

                            {/* image */}
                            <img
                                src={apsara}
                                className="image-main-section"
                                alt="persia darbar logo"
                            ></img>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div className="container-lg">
                    <div className="sections" style={{ padding: "20px" }}>
                        <div className="cardcase" ref={ref}>
                            {inView && (
                                <div
                                    className="cardcasestudyHeader animate-from-top"
                                    style={{
                                        fontFamily: "'Poppins', sans-serif",
                                    }}
                                >
                                    Success Story
                                </div>
                            )}

                            <CaseStudyCarousel />


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Featuredcasestudyapsara;
