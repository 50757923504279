import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import rbhome from "../../images/CaseStudy/R&B/rbhome.png";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import rbowner from "../../images/CaseStudy/R&B/rbfounder.png";
import rbone from '../../images/CaseStudy/R&B/R&B 1.png';
import rbtwo from '../../images/CaseStudy/R&B/R&B 2.png';


const Rb = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        document.title = "Success Stories | Restaverse";
        window.scrollTo(0, 0);
    }, []);


    const infoAboutCompany = {
        Brand: `Ribbons & Balloons`,
        Platform: "Zomato & Swiggy",
        Cuisine: "Desserts",
        Location: "Mumbai",
        AOV: "Mid",
        "Target Audience": "MassPremium",
    };

    const challenge = {
        text1: "Weak SEO, stockouts, lost young audience",
        text2: `Ribbons and Balloons, a legacy brand, faced issues with brand positioning and a weak SEO strategy despite having an established menu with photos and descriptions from a previous agency. They experienced stockouts across franchise and company outlets due to inefficiencies in their stock purchasing process. Their target audience, particularly Gen Z and millennials, had stopped ordering, signaling a need to reassess both their menu offerings and overall brand positioning.s`,
    };

    const impact = {
        impactCards: {
            card1: {
                header: "Menu Opens",
                figures: 80,
                figurePrefix: "",
                figuresPostfix: "%",
                content: "Increase in menu opens",
            },
            card2: {
                header: "Order Value",
                figures: 30,
                figuresPostfix: "%",
                content: "Increase average order value",
            },
            card3: {
                header: "Conversion",
                figures: 20,
                figuresPostfix: "%",
                content: "Increase in menu-to-order conversions",
            },
        },
        owner_views: {
            title: "Ribbons and Balloons",
            description: `At our scale of around 100 stores, managing aggregator ops was fairly challenging, right from data collection to making insights. We tried having this full function in-house but the tech that restaverse brought with them, really helped us on supply-chain, inventory and even figure out how we can improvise existing SKU's (Stock keeping units). They did a customer psychology session with us that really helped us understand our consumer better and pivot from there to re-branding, thereafter helping us improve our overall customer facing metrics and the revenues.`,
            name: "Yash Shetty",
            designation: "Director & CEO, Ribbons and Balloons",
            owner_img: rbowner
        },
    };


    const solutions = {
        1: {
            header: "Menu Revamp",
            content: `We began by addressing the core issue with their menu. Through competitor benchmarking, we ensured that their product offerings were aligned with current trends and appealed to Gen Z and millennials, who prioritize both taste and aesthetics. Aesthetic upgrades were made to enhance the visual appeal of the desserts, understanding that this demographic values presentation. Furthermore, we capitalized on the brand’s strength in chocolate cakes, refining the product and ensuring it was positioned as their key differentiator in the market.`,
            src: rbone,
        },
        2: {
            header: "Brand Positioning & Inventory Optimization",
            content:
                "Alongside the menu changes, we focused on refining the overall brand positioning. The goal was to strengthen the emotional connection with the target audience, highlighting the legacy and quality that Ribbons and Balloons embodies. To solve the stockouts issue, we streamlined inventory management and purchasing practices, reducing inefficiencies and ensuring smoother operations across all outlets. Additionally, we optimized their advertising spend, achieving better results with a reduced budget, thus driving more cost-effective growth.",
            src: rbtwo,
        }

    };

    return (
        <>
            <div style={{ backgroundColor: "#f7f5ff" }}>
                <div
                    // className="casestudy "

                    className="container-lg"
                >
                    <div
                        className="casestudy"
                        style={{ fontFamily: "Poppins , sans-serif" }}
                    >
                        <div className="main-section">
                            {/* left-main-section */}
                            <div className="left-main-section">
                                <div className="left-section-desc">
                                    Ribbons & Balloons : Revenue Growth via stock out issue analysis and SSSG
                                </div>
                                <div className="info-tags">
                                    {Object.keys(infoAboutCompany).map((key) => (
                                        <Infotags
                                            key={key}
                                            header={key}
                                            value={infoAboutCompany[key]}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* image */}
                            <img src={rbhome} className="image-main-section"></img>
                        </div>


                        <div className="sections">
                            <nav className="section-navbar">
                                <a href="#challenge">Challenge</a>
                                <a href="#solution">Solution</a>
                                <a href="#impact">Impact</a>
                            </nav>

                            <section id="challenge">
                                <ChallengeSection
                                    text1={challenge.text1}
                                    text2={challenge.text2}
                                />
                                {/* <Challenge/> */}
                            </section>

                            <section id="solution">
                                <SolutionSection solutions={solutions} />
                            </section>

                            <section id="impact">
                                {/* <Impact /> */}
                                <ImpactSection impact={impact} />
                            </section>

                            <div className="cardcase" ref={ref}>
                                {inView && (
                                    <div className="cardcasestudyHeader animate-from-top">
                                        Success Story
                                    </div>
                                )}

                                <CaseStudyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <SuceessStory
                    titletext="Be ahead of the curve"
                    desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
                    buttontext="Join Waitlist"
                    redirect="/contact-us"
                />
            </div>
        </>
    );
};

export default Rb;
